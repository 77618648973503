import { Box, Grid, Typography, MuiIcon } from "components"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent, PageFooter } from "layouts"
import styled from "styled-components"
import { ClaimCard } from "components/advance/ClaimCard"
import paths from "routes/paths"
import Theme from "theme/custom"
import { API } from "../e-claim-landing"
import { employeeIsHr } from "utils/helper"
import { ReactComponent as Point } from "assets/icon/point.svg"
const Content = styled(Box)`
  padding: 16px;
  background-color: ${Theme.colors["Text/Background"]};
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; w100?: boolean; mt?: string; al?: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  width: ${(props) => (props.w100 ? "100%" : "none")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  align-items: ${(props) => props.al};
`
const Label = styled.div`
  padding-left: "16px";
  padding-right: "16px";
  padding-bottom: "8px";
  padding-top: "8px";
  display: "flex";
  justify-content: "space-between";
  background-color: ${Theme.colors["Other/Danger"]};
  align-items: "center";
`
const LandingPageComponent = (props: any) => (
  <>
    <PageContent
      title="สวัสดิการทั้งหมด"
      type="gray"
      onBack={props.handleClose}
      pageBackgroundColor={Theme.colors["Text/Background"]}
    >
      <div
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "8px",
          paddingTop: "8px",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: Theme.colors["Primary/Primary Text"],
          alignItems: "center",
        }}
      >
        <Typography variant="body1" color={Theme.colors["White / White"]}>
          คะแนนที่มีอยู่
        </Typography>
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Point></Point>
          <Typography variant="body1" color={Theme.colors["White / White"]} isCurrency>
            {props.remainingPoint} คะแนน
          </Typography>
        </div>
      </div>
      <Content>
        <Grid container>
          {props.layouts.map((layout: any, index: number) => (
            <>
              <ContainerFlex js="start" w100>
                <ContainerFlex js="flex-start" al="center" row w100 mt={index === 0 ? "8px" : "32px"}>
                  <Box mr="12px" display="flex" alignItems="center" justifyContent="center">
                    <MuiIcon name={layout.icon} fontSize="small" color={layout.iconColor} />
                  </Box>
                  <Typography variant="h3" color={layout.titleColor}>
                    {layout.title}
                  </Typography>
                </ContainerFlex>
                <Box mt="4px" mb="8px">
                  <Typography variant="subtitle2" color={Theme.colors["Text/Secondary Text"]}>
                    {layout.description}
                  </Typography>
                </Box>
              </ContainerFlex>
              {layout.sectionList.map((section: any) => (
                <>
                  {/* <ContainerFlex js="start" row w100 mt="8px">
                    <Typography variant="h6" color="Text/Black2">
                      {section.requestTypeTitle}
                    </Typography>
                  </ContainerFlex> */}
                  <Grid container spacing={3}>
                    {props.claimRequests(section.requestTypeList).map((data: any, index: number) => (
                      <ClaimCard
                        key={data.name}
                        onClick={() => props.handleClickRequest(data)}
                        type={data.type}
                        name={data.name}
                        approvedRequest={data.approvedCount}
                        totalAmount={data.approvedAmount}
                        title={data.title}
                        icon={data.icon}
                        iconColor={data.iconColor}
                        chipTitleColor={data.chipTitleColor}
                        chipBackgroundColor={data.chipBackgroundColor}
                      />
                    ))}
                  </Grid>
                </>
              ))}
            </>
          ))}
        </Grid>
      </Content>
      {/* <Box style={{ height: "82px" }} /> */}
    </PageContent>
    {/* <PageFooter page="claim-list" isHr={props.userIsHr} /> */}
  </>
)

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo } = props
    const { useQuery, useCallback, useMemo } = hooks
    const { data } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      variables: {
        employeeId: currentUserInfo.employee.id,
      },
      fetchPolicy: "network-only",
    })

    const userIsHr = useMemo(() => employeeIsHr(currentUserInfo.employee.role), [currentUserInfo])
    const handleClickRequest = (value: any) => {
      localStorage.setItem("selected_request", JSON.stringify(value))
    }

    const handleClose = useCallback(() => {
      paths.landingPath().push()
    }, [])

    const claimRequests = useCallback(
      (requests: string[]) => {
        if (data?.claimRequestConfigs.requests)
          return data?.claimRequestConfigs.requests.filter((request: any) => requests.includes(request.name))
        return []
      },
      [data?.claimRequestConfigs],
    )

    const remainingPoint = useMemo(() => currentUserInfo?.employee.currentPoint, [currentUserInfo])

    return {
      layouts: data?.claimRequestConfigs.layouts.allRequests || [],
      claim_requests: data?.claimRequestConfigs.requests || [],
      handleClickRequest,
      handleClose,
      claimRequests,
      userIsHr,
      remainingPoint,
    }
  }),
)

export default enhancer(LandingPageComponent)
