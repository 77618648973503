import { useMsal } from "@azure/msal-react"
import { removeToken } from "api"
import { Box, Button, Typography } from "components"
import { loginRequest } from "constants/authConfig"
import { compose, withHooks, withStores } from "enhancers"
import styled from "styled-components/macro"
import Theme from "theme/custom"
import { paths } from "utils/helper"

interface SignInPageProps {
  handleClickLogin: () => void
  hasAccount: boolean
  handleClickLogoutAzure: () => void
  account: any
  currentUserInfo: any
}

const LoginButton = styled(Button)`
  color: ${Theme.colors["White / White"]};
  background-color: ${Theme.colors["Dark Blue/Primary Text"]};
  width: 250px;
  margin-top: 12px;
  border: none;
  &:focus {
    background-color: ${Theme.colors["Dark Blue/Primary Text"]};
  }
  &:active {
    background-color: ${Theme.colors["Dark Blue/Primary Text"]};
  }
  &:hover {
    opacity: 0.8;
    background-color: ${Theme.colors["Dark Blue/Primary Text"]};
  }
`

const SignInPage = ({
  handleClickLogin,
  hasAccount,
  handleClickLogoutAzure,
  account,
  currentUserInfo,
}: SignInPageProps) => (
  <>
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt="300px">
      <Typography variant="h1">E-CLAIM</Typography>
      {hasAccount ? (
        <>
          <Typography variant="h3">Welcome {account.name}</Typography>
          {!currentUserInfo && (
            <Typography variant="body1" color={Theme.colors["Other/Warning"]}>
              Please contact admin to access this app
            </Typography>
          )}

          <LoginButton variant="outlined" onClick={handleClickLogoutAzure}>
            Logout Azure AD
          </LoginButton>
        </>
      ) : (
        <LoginButton variant="outlined" onClick={handleClickLogin}>
          Login with Azure AD
        </LoginButton>
      )}
    </Box>
  </>
)

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { useHandleSubmit, useMutation, useMemo, useParams, useCallback } = hooks
    const { signUp, resetPassword, setErrors, currentUserInfo } = props

    const { instance, accounts, inProgress } = useMsal()

    const handleClickLogin = useCallback(() => {
      instance
        .loginRedirect(loginRequest)
        .then((response) => {
          // instance.ssoSilent(loginRequest)
          console.log(response)
        })
        .catch((error) => {
          // handle error, either in the library or coming back from the server
          console.log("Error msal !!!!! : ", error)
        })
    }, [instance])

    const hasAccount = useMemo(() => {
      return accounts.length > 0
    }, [accounts])

    const handleClickLogoutAzure = useCallback(() => {
      if (hasAccount) {
        instance.logout({
          account: accounts[0],
          onRedirectNavigate: (url) => {
            instance.setActiveAccount(null)
            removeToken()
            window.location.href = paths.landingPath()
            return true
          },
        })
        instance.setActiveAccount(null)
      }
    }, [hasAccount, instance, accounts])

    const account = useMemo(() => {
      if (hasAccount) return accounts[0]
    }, [hasAccount, accounts])

    return {
      handleClickLogoutAzure,
      handleClickLogin,
      hasAccount,
      account,
      currentUserInfo,
    }
  }),
)

export default enhancer(SignInPage)
