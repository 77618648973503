import { Box as MuiBox, Grid, Icon, Link, Typography } from "components"
import Box from "@material-ui/core/Box"
import { IconName } from "components/common/Icon"
import { MuiIconCollections } from "components/common/MuiIcon"
import styled from "styled-components"
import Theme from "theme/custom"
import { toCurrency } from "utils/helper"

const Categories = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  height: fit-content;
  // min-width: fit-content;
  // max-width: fit-content;
  padding: 2px;
  // margin-left: 8px;
  max-width: 156px;
`

const CategoriesComponent = (props: { type: string; chipBackgroundColor: string; chipTitleColor: string }) => {
  const { type, chipBackgroundColor, chipTitleColor } = props

  // return (
  //   <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "4px" }}>
  //     <Categories color={chipBackgroundColor}>
  //       <Typography variant="caption" color={chipTitleColor}>
  //         {type}
  //       </Typography>
  //     </Categories>
  //     <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
  //       <Icon name="point" style={{ width: "10px", height: "10px" }} />
  //       <Typography variant="subtitle1">ตามจำนวนที่จ่าย</Typography>
  //     </div>
  //   </div>
  // )
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Categories color={chipBackgroundColor}>
        <Typography variant="caption" color={chipTitleColor}>
          {type}
        </Typography>
      </Categories>
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <Icon name="point" style={{ width: "10px", height: "10px" }} />
        <Typography variant="subtitle1">ตามจำนวนที่จ่าย</Typography>
      </div>
    </div>
  )
}

const FooterContainer = styled.div`
  bottom: 4px;
  padding: 8px 0px 0px 0px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  border-top: 1px solid ${Theme.colors["Gray/Flat Button Bg"]};
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; al: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: ${(props) => props.al};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
`

interface FooterProps {
  totalAmount: number
  approvedRequest: number
}

const Footer = (props: FooterProps) => {
  const { totalAmount, approvedRequest } = props
  return (
    <FooterContainer>
      <Grid item xs={12} sm={12}>
        <ContainerFlex js="space-between" row al="">
          <ContainerFlex js="" al="flex-start">
            <Box mb="8px">
              <Typography variant="subtitle1" color="Gray/Flat Button Bg">
                จำนวนครั้งที่เบิก
              </Typography>
            </Box>
            <Typography variant="body2" color="Text/Black2">
              {toCurrency(approvedRequest, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
            </Typography>
          </ContainerFlex>
          <ContainerFlex js="" al="flex-end">
            <Box mb="8px">
              <Typography variant="subtitle1" color="Text/Gray Success">
                จำนวนเงินที่เบิก
              </Typography>
            </Box>
            <Typography variant="body2" color="Text/Black2">
              {toCurrency(totalAmount, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
            </Typography>
          </ContainerFlex>
        </ContainerFlex>
      </Grid>
    </FooterContainer>
  )
}

const ClaimCardClickContainer = styled(Box)<{ ["display-only"]: boolean }>`
  &:hover {
    box-shadow: ${(props) => (props["display-only"] ? "none" : "-1px 0px 7px 4px rgba(142, 201, 231, 0.75)")};
  }
`

export interface ClaimCardProps {
  type: string
  name?: string
  title: string
  iconColor: string
  chipTitleColor: string
  chipBackgroundColor: string

  displayOnly?: boolean
  fullSize?: boolean

  icon: IconName
  onClick?: (request?: any) => void

  totalAmount: number
  approvedRequest: number
}

export const ClaimCard = (props: ClaimCardProps) => {
  const {
    totalAmount = 0,
    displayOnly = false,
    approvedRequest,
    title,
    type,
    fullSize = false,
    icon,
    onClick,
    name,
    iconColor,
    chipTitleColor,
    chipBackgroundColor,
  } = props

  const Content = (
    <ClaimCardClickContainer
      title={title}
      padding="8px"
      border={`1px solid ${Theme.colors["Gray/Flat Button Bg"]}`}
      borderRadius={4}
      bgcolor={Theme.colors["White / White"]}
      display-only={displayOnly}
      // minWidth={147}
      height={142}
    >
      {fullSize ? (
        <Box display="flex" flexDirection="column" padding="8px">
          <Box display="flex" justifyContent="space-between" marginBottom="5px">
            <Box display="flex" alignItems="center">
              <Box padding="4px">
                <Icon width="32px" height="32px" name={icon} color={iconColor} />
              </Box>
              <Typography variant="body1" color="Text/Black2">
                {title}
              </Typography>
            </Box>
            {/* <CategoriesComponent
              type={type}
              chipBackgroundColor={chipBackgroundColor}
              chipTitleColor={chipTitleColor}
            /> */}
          </Box>
          <CategoriesComponent type={type} chipBackgroundColor={chipBackgroundColor} chipTitleColor={chipTitleColor} />
          <Footer approvedRequest={approvedRequest} totalAmount={totalAmount} />
        </Box>
      ) : (
        <Box textAlign="center" style={{ paddingBottom: "8px", paddingTop: "8px" }}>
          <Box padding="4px">
            <Icon width="32px" height="32px" name={icon} color={iconColor} />
          </Box>
          <Box padding="0px 16px">
            <Typography
              title={title}
              variant="body1"
              color="Text/Black2"
              style={{
                height: "40px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {title}
            </Typography>
            <div style={{ display: "flex", alignItems: "center", gap: "4px", justifyContent: "center" }}>
              <Icon name="point" style={{ width: "10px", height: "10px" }} />
              <Typography variant="subtitle1">ตามที่จ่ายจริง</Typography>
            </div>
          </Box>
        </Box>
      )}
    </ClaimCardClickContainer>
  )

  return (
    <Grid item xs={fullSize ? 12 : 6} mb="8px">
      {displayOnly ? (
        Content
      ) : (
        <Link to="/claim-detail" onClick={onClick}>
          {Content}
        </Link>
      )}
    </Grid>
  )
}
