import { Box } from "components"
import { PropsWithChildren, useMemo } from "react"
import styled from "styled-components"
import Theme from "theme/custom"
import { theme } from "../constants"
import { Title } from "./PageContentTitle"
import { linearGradient } from "polished"
import { ReactComponent as Report } from "assets/icon/report.svg"

const TitleBox = styled(Box)<{ bgcolor?: string; ["blue-background-height"]?: string }>`
  position: relative;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : theme.color.white)};
  padding-bottom: ${(props) => props["blue-background-height"]};
  display: flex;
  justify-content: center;
`

const LayoutBox = styled(Box)<{ ["overlap-height"]?: string }>`
  position: relative;
  margin-top: -${(props) => (props["overlap-height"] ? props["overlap-height"] : "0px")};
`

const BlueContainer = styled(Box)<{ ["blue-container-height"]?: string }>`
  position: relative;
  background: ${Theme.colors["Dark Blue/Primary Text"]};
  height: ${(props) => (props["blue-container-height"] ? props["blue-container-height"] : "0px")};
`
const BgBlueContainer = styled(Box)<{ ["blue-container-height"]?: string }>`
  position: relative;
  background: ${Theme.colors["Dark Blue/Primary Text"]};
  height: ${(props) => (props["blue-container-height"] ? props["blue-container-height"] : "0px")};
`

const ChildrenContainer = styled.div`
  position: relative;
  height: 100%;
`

const PageContentConfig = {
  primary: {
    titleColor: Theme.colors["White / White"],
    titleBackgroundColor: Theme.backgroundGradient,
  },
  secondary: {
    titleColor: Theme.colors["Gray/Primary Text"],
    titleBackgroundColor: Theme.colors["White / White"],
  },
  linear: {
    titleColor: Theme.colors["White / White"],
    titleBackgroundColor: Theme.colors["Primary/Hover"],
  },
  gray: {
    titleColor: Theme.colors["Text/Primary Text"],
    titleBackgroundColor: Theme.colors["Text/Background"],
  },
}

interface PageContentProps extends PropsWithChildren<unknown> {
  title: string
  type?: keyof typeof PageContentConfig
  titleCentered?: boolean
  // personalRemain?: number
  // onFooterClick?: (props?: any) => void
  // footerButtonTxt?: string
  // to?: string
  onListClickTo?: string
  onBack?: () => void
  // titleDark?: boolean
  pageBackgroundColor?: string
  showHomeIcon?: boolean
  extendedTitleHeight?: string
  overlapHeight?: string

  showFilter?: boolean
  openFilterModal?: void
  hasFilter?: boolean

  showLogout?: boolean
  showDashBoardIcon?: boolean
  filtered?: boolean
}

export const PageContent = (props: PageContentProps) => {
  const {
    title,
    showDashBoardIcon,
    type = "primary",
    children,
    titleCentered = true,
    showHomeIcon,
    // personalRemain,
    // footerButtonTxt,
    // onFooterClick,
    // to,
    onListClickTo,
    onBack,
    // titleDark = false,
    pageBackgroundColor = Theme.colors["White / White"],

    extendedTitleHeight,
    overlapHeight,
    // blueContainerHeight = "0px",
    // childrenMarginTop,
    // pageBorderTop,
    // childrenPadding,
    // titleBg = "primary",
    showFilter,
    openFilterModal,
    // hasFilter,
    showLogout,
    filtered,
  } = props

  // const isShowFooterButton = useMemo(() => !(to || onFooterClick), [to, onFooterClick])

  // const handleFooterClick = useCallback(() => {
  //   if (to) {
  //     if (onFooterClick) return onFooterClick
  //     return undefined
  //   }
  // }, [to, onFooterClick])

  // const bgHeightBlue = blueContainerHeight ? `${parseInt(blueContainerHeight, 10) + 56}px` : "0px"

  const { titleBackgroundColor, titleColor } = PageContentConfig[type as keyof typeof PageContentConfig]

  return (
    <div
      style={{
        backgroundColor: pageBackgroundColor,
        position: "relative",
        // minWidth: "357px",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      {/* <BgBlueContainer blue-container-height={bgHeightBlue}></BgBlueContainer> */}
      {/* <Box
        style={{
          background: titleBackgroundColor,
          width: "100%",
          height: "246px",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: 0,
        }}
      ></Box> */}
      <TitleBox
        // minWidth={357}
        overflow="hidden"
        mx="auto"
        bgcolor={titleBackgroundColor}
        blue-background-height={extendedTitleHeight}
      >
        <Title
          onBack={onBack}
          onListClickTo={onListClickTo}
          title={title}
          titleColor={titleColor}
          centered={titleCentered}
          // titleDark={titleDark}
          // titleBg={titleBg}
          showFilter={showFilter}
          openFilterModal={openFilterModal}
          // hasFilter={hasFilter}
          showLogout={showLogout}
          showDashBoardIcon={showDashBoardIcon}
          showHomeIcon={showHomeIcon}
          filtered={filtered}
        />
      </TitleBox>
      <LayoutBox
        // minWidth={357}
        maxWidth={784}
        overflow="hidden"
        mx="auto"
        overlap-height={overlapHeight}
        // bgcolor={pageBackgroundColor}
        // border-top={pageBorderTop}
        // margin-top={bgHeightBlue}
      >
        {/* <Title
          onBack={onBack}
          onListClickTo={onListClickTo}
          title={title}
          centered={titleCentered}
          personalRemain={personalRemain}
          titleDark={titleDark}
          titleBg={titleBg}
          showFilter={showFilter}
          openFilterModal={openFilterModal}
          hasFilter={hasFilter}
          showLogout={showLogout}
        /> */}
        {/* <BlueContainer blue-container-height={blueContainerHeight}></BlueContainer> */}

        <ChildrenContainer>{children}</ChildrenContainer>

        {/* <Hidden when={isShowFooterButton}>
          <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
            <Footer onClick={handleFooterClick} txt={footerButtonTxt} to={to} />
          </Box>
        </Hidden> */}
      </LayoutBox>
    </div>
  )
}
