import { ApolloError } from "@apollo/client"
import { Card, CardContent } from "@material-ui/core"
import { removeToken } from "api"
import { Box, Button, Grid, Icon, MuiIcon, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent, PageFooter } from "layouts"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom"
import userStore from "stores/userStore"
import styled from "styled-components"
import Theme from "theme/custom"
import { employeeIsHr, gql } from "utils/helper"
const SectionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

const ListContainer = styled(Box)`
  padding: 16px;
`

const CustomIconList = styled(MuiIcon)`
  font-size: 20px;
  margin-left: 8px;
`

const CurrentUser = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; w100?: boolean; mt?: string; al?: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  width: ${(props) => (props.w100 ? "100%" : "none")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  align-items: ${(props) => props.al};
`

const LandingComponent = (props: any) => (
  <>
    <PageContent
      title="Reimbursement"
      titleCentered
      onListClickTo="/claim-requests"
      extendedTitleHeight="180px"
      overlapHeight="170px"
      showDashBoardIcon={props.userIsHr}
    >
      <div>
        <ListContainer>
          <CurrentUser>
            <Box style={{ position: "relative" }}>
              <Box display="flex" justifyContent="" flexDirection="column">
                <Typography variant="h1" color="White / White">
                  สวัสดี
                </Typography>
                <Typography variant="h1" color="White / White">
                  {props.currentUserInfo?.employee.firstName + " " + props.currentUserInfo?.employee.lastName}
                </Typography>
                <div style={{ marginTop: "12px" }} />
                <Card style={{ boxShadow: " 0 0 1px" }}>
                  <CardContent
                    style={{ display: "flex", flexDirection: "column", padding: "16px", alignItems: "center" }}
                  >
                    <Typography variant="h4" color={Theme.colors["Primary/Hover"]}>
                      คะแนนคงเหลือปี {props.year}
                    </Typography>
                    <div style={{ marginBottom: "16px", marginTop: "16px", display: "flex", gap: "8px" }}>
                      <Icon name="point" />
                      <Typography variant="Body/32" color={Theme.colors["Text/Primary Text"]} isCurrency>
                        {props.currentPoint}
                      </Typography>
                      <Typography variant="body1" color={Theme.colors["Text/Primary Text"]}>
                        คะแนน
                      </Typography>
                    </div>

                    <Typography variant="h4" color={Theme.colors["Text/Line"]} isCurrency>
                      คิดเป็นเงิน {props.currentPoint} บาท
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
              <Box style={{ position: "absolute", right: 0, top: 0 }}>
                <Link
                  to="/history"
                  style={{ display: "flex", alignItems: "center", color: Theme.colors["White / White"] }}
                >
                  <MuiIcon name="Restore" style={{ fontSize: "14px", marginRight: "2px" }} />
                  <Typography variant="subtitle1" color="White / White">
                    ประวัติการเบิก
                  </Typography>
                </Link>
              </Box>
            </Box>
          </CurrentUser>
          <SectionHeader style={{ display: "flex", marginBottom: "16px" }}>
            <Box mr="8px">
              <Typography variant="h3" color="black">
                สวัสดิการพนักงาน
              </Typography>
            </Box>
            <Box mt="2px">
              <Typography variant="subtitle1" color="Black">
                ( {props.length} รายการ )
              </Typography>
            </Box>
          </SectionHeader>
          <Grid container>
            {props.layouts.map((layout: any, index: number) => (
              <>
                <ContainerFlex js="start" w100>
                  <ContainerFlex js="flex-start" al="center" row w100 mt={index === 0 ? "8px" : "32px"}>
                    <Box mr="12px" display="flex" alignItems="center" justifyContent="center">
                      <MuiIcon name={layout.icon} fontSize="small" color={layout.iconColor} />
                    </Box>
                    <Typography variant="h3" color={layout.titleColor}>
                      {layout.title}
                    </Typography>
                  </ContainerFlex>
                  <Box mt="4px" mb="8px">
                    <Typography variant="subtitle2" color={Theme.colors["Text/Secondary Text"]}>
                      {layout.description}
                    </Typography>
                  </Box>
                </ContainerFlex>
                {layout.sectionList.map((section: any) => (
                  <Grid container spacing={3}>
                    {props.claimRequests(section.requestTypeList).map((data: any, index: number) => (
                      <ClaimCard
                        key={data.name}
                        onClick={() => props.handleClickRequest(data)}
                        type={data.type}
                        name={data.name}
                        approvedRequest={data.approvedCount}
                        totalAmount={data.approvedAmount}
                        title={data.title}
                        icon={data.icon}
                        iconColor={data.iconColor}
                        chipTitleColor={data.chipTitleColor}
                        chipBackgroundColor={data.chipBackgroundColor}
                      />
                    ))}
                  </Grid>
                ))}
              </>
            ))}
          </Grid>
        </ListContainer>
      </div>
      {/* <Box style={{ height: "82px" }} /> */}
    </PageContent>
    {/* <PageFooter page="home" isHr={props.userIsHr} /> */}
  </>
)

export const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS($employeeId: String!) {
      claimRequestConfigs(employeeId: $employeeId) {
        workFlowConfigs
        master
        layouts
        requests
        usage
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
          department
        }
      }
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useQuery, useLazyQuery, useEffect, useCallback, useMemo, useState } = hooks

    const { data: currentUser, refetch } = useQuery(API.GET_CURRENT_USER, {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        userStore.setCurrentUser(data.getCurrentUser)
      },
      onError: (error: ApolloError) => {
        console.log("GET CURRENT USER ERROR : ", error)
        userStore.setCurrentUser(null)
      },
    })
    const currentUserInfo = useMemo(() => currentUser?.getCurrentUser, [currentUser])

    const [fetchClaimRequests, { data }] = useLazyQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      variables: {
        employeeId: currentUserInfo?.employee.id,
      },
      nextFetchPolicy: "network-only",
    })
    const config = useMemo(() => data?.claimRequestConfigs, [data])

    const storeItem = useCallback(() => {
      localStorage.setItem("workflow", JSON.stringify(config.workFlowConfigs))
      localStorage.setItem("master", JSON.stringify(config.master))
    }, [config])

    const userIsHr = useMemo(() => employeeIsHr(currentUserInfo?.employee.role), [currentUserInfo])

    const handleClickRequest = (value: any) => {
      localStorage.setItem("selected_request", JSON.stringify(value))
    }

    const claimRequestConfigs = useMemo(() => {
      let array: any[] = []
      if (data) {
        let requestsDic: any = {}
        data?.claimRequestConfigs.requests.forEach((request: any) => (requestsDic[request.name] = request))
        array = data?.claimRequestConfigs.layouts.topRequests.map((name: string) => {
          const existed = requestsDic[name]
          return existed
        })
      }
      return array
    }, [data])

    const currentPoint = useMemo(() => currentUserInfo?.employee.currentPoint || 0, [currentUserInfo])

    const claimRequests = useCallback(
      (requests: string[]) => {
        if (data?.claimRequestConfigs.requests)
          return data?.claimRequestConfigs.requests.filter((request: any) => requests.includes(request.name))
        return []
      },
      [data?.claimRequestConfigs],
    )

    useEffect(() => {
      localStorage.removeItem("selected_request")
      if (isEmpty(data) && currentUserInfo) fetchClaimRequests()
    }, [data, fetchClaimRequests, currentUserInfo])

    useEffect(() => {
      if (config) {
        storeItem()
      }
    }, [config, storeItem])

    // useEffect(() => {
    //   const performAction = () => {
    //     refetch()
    //   }

    //   const intervalId = setInterval(performAction, 15000)

    //   return () => clearInterval(intervalId)
    // }, [refetch])

    const year = useMemo(() => new Date().getFullYear() + 543, [])
    return {
      claimRequestConfigs,
      length: data?.claimRequestConfigs.requests.length,
      handleClickRequest,
      currentUserInfo,
      userIsHr,
      currentPoint,
      year,
      layouts: config?.layouts.allRequests || [],
      claimRequests,
    }
  }),
)

export default enhancer(LandingComponent)
