import styled from "styled-components"

import { DocumentConfig } from "./UploadDocumentCard"
import { Typography } from "components/common"
import Theme from "theme/custom"

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
`

const List = styled.ul`
  margin-top: 8px;
  margin-left: -16px;
`

export interface RequiredDocumentCardProps {
  documents: DocumentConfig[]
}

export const RequiredDocumentCard = (props: RequiredDocumentCardProps) => {
  const { documents } = props

  return (
    <Container>
      {documents.length > 0 && (
        <Typography variant="h3" color={Theme.colors["Text/Primary Text"]}>
          เอกสารที่ต้องใช้เบิก
        </Typography>
      )}
      <List>
        {documents.map((doc, ind) => (
          <li key={ind}>
            <Typography variant="h4" color={Theme.colors["Text/Secondary Text"]}>
              {doc.title}
            </Typography>
          </li>
        ))}
      </List>
    </Container>
  )
}
