import { ApolloClient, InMemoryCache, ApolloProvider, from, gql } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"

import { createUploadLink } from "apollo-upload-client"

import ENV from "env"

let client

const TOKEN = "AUTHENTICATION_TOKEN"
export const getToken = () => localStorage.getItem(TOKEN)
export const setToken = (token) => localStorage.setItem(TOKEN, token)
export const removeToken = () => localStorage.removeItem(TOKEN)

const httpLink = createUploadLink({
  uri: ENV.API_ENDPOINT,
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  return {
    headers: {
      ...headers,
      "x-tenant-id": "scg",
      "Auth-Token": token || "",
    },
  }
})

const errorLink = onError((error) => {
  const { networkError } = error
  switch (networkError?.statusCode) {
    case 403:
      return
    default:
      return
  }
})

client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
})

export const ApiProvider = (props) => <ApolloProvider client={client}>{props.children}</ApolloProvider>

export const resetStore = client.resetStore
export const clearStore = client.clearStore

export { gql }

export default client
