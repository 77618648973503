import { DialogActions, DialogContent, Dialog as MuiDialog, TextareaAutosize } from "@material-ui/core"
import { Box, Button, Field, Form, Grid, Select, Typography } from "components"
import { compose, withFormik, withHooks } from "enhancers"
import styled from "styled-components"
import Theme from "theme/custom"
import { Yup } from "utils/helper"
const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: hidden;
  }
`

const ModalContainer = styled(DialogContent)`
  /*max-width: 500px;
  min-width: 340px;*/
  height: 100%;
  /*min-height: 200px;*/
  padding: 16px;
`

const ContentContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  margin-bottom: 16px;
`

const TextAreaLabelContainer = styled("div")`
  width: 100%;
`

const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
`

const ActionButton = styled(Button)`
  width: 100%;
`

interface ApproveModalProps {
  isOpen: boolean
  title: string
  onClose: () => void
  onConfirm: (date: string) => void
  amount: string
  rejectReasonsOptions: any
  value: string
  handleChangeValue: () => void
  handleClose: () => void
  handleConfirm: () => void
}

const RejectModal = (props: ApproveModalProps) => {
  return (
    <Form>
      <Dialog open={props.isOpen} closeAfterTransition>
        <ModalContainer>
          <ContentContainer>
            <TitleContainer>
              <Typography variant="h3" color="Text/Black2">
                ต้องการปฏิเสธการเบิกสวัสดิการ ?
              </Typography>
            </TitleContainer>
            <Box mb="4px">
              <Typography variant="body1" color="Text/Gray Preview">
                ไม่อนุมัติการเบิก
              </Typography>
            </Box>
            <Box mb="16px">
              <Typography variant="h6" color={Theme.colors["Primary/Primary Text"]}>
                {props.title + " " + props.amount + " "}
                บาท
              </Typography>
            </Box>
            <TextAreaLabelContainer>
              <Typography variant="body1" color="Text/Black2">
                โปรดระบุเหตุผล*
              </Typography>
              <Grid mb={4}>
                <Field
                  component={Select}
                  name="rejectOption"
                  type="text"
                  placeholder="โปรดระบุเหตุผล"
                  options={props.rejectReasonsOptions}
                  fullWidth
                  required
                />
              </Grid>
            </TextAreaLabelContainer>
            <TextAreaLabelContainer>
              <Typography variant="body1" color="Text/Black2">
                หมายเหตุ*
              </Typography>
            </TextAreaLabelContainer>
            <TextareaAutosize
              style={{
                width: "calc(100% - 24px)",
                padding: "12px",
                borderRadius: 8,
                resize: "none",
                marginTop: 8,
              }}
              rowsMax={4}
              rowsMin={4}
              maxLength={250}
              value={props.value}
              placeholder="โปรดระบุ"
              onChange={props.handleChangeValue}
            />
          </ContentContainer>
        </ModalContainer>
        <DialogActions>
          <ActionContainer>
            <ActionButton variant="outlined" onClick={props.handleClose} mr="16px">
              ปิดหน้าต่างนี้
            </ActionButton>
            <ActionButton variant="contained" onClick={props.handleConfirm}>
              ยืนยัน
            </ActionButton>
          </ActionContainer>
        </DialogActions>
      </Dialog>
    </Form>
  )
}
const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      rejectOptions: "",
      remark: "",
    }),
    validationSchema: Yup.object().shape({
      rejectOptions: Yup.string().required("ต้องมี"),
      remark: Yup.string().required().nullable(),
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const { isOpen, onClose, title, onConfirm, amount, rejectReasonsOptions, setValues, values, setFieldValue } = props
    const { useCallback, useMemo } = hooks

    const handleChangeValue = useCallback((event: any) => {
      setFieldValue("remark", event.target.value)
    })

    const isRequiredReason = useMemo(() => {
      return values.rejectOption
    }, [values.rejectOption])

    const handleClose = useCallback(() => {
      onClose()
      setValues({ remark: "" })
    }, [onClose])

    const handleConfirm = useCallback(() => {
      if (isRequiredReason) {
        onConfirm(values.remark || null, values.rejectOption.toString())
        handleClose()
      }
    }, [onConfirm, handleClose, values])

    return {
      isOpen,
      title,
      onConfirm,
      amount,
      value: values.remark,
      handleChangeValue,
      handleClose,
      handleConfirm,
      rejectReasonsOptions,
    }
  }),
)

const RejectModalComponent = enhancer(RejectModal)

export default RejectModalComponent
