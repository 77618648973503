import { gql } from "@apollo/client"
import { Box, Modal, Typography } from "components"
import { ClaimRequestHistoryItem } from "components/advance/ClaimRequestHistoryItem"
import { StatusTab } from "components/advance/StatusTab"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent } from "layouts/PageContent"
import { every, isEmpty } from "lodash"
import paths from "routes/paths"
import styled from "styled-components"
import FilterClaimRequests from "./FilterModal"
import InfiniteScroll from "react-infinite-scroll-component"
import Loader from "components/Loader"

const HistoryContainer = styled.div`
  margin-top: 16px;
`

const ClaimRequestsPageComponent = (props: any) => (
  <PageContent
    title="รายการขอเบิก"
    type="secondary"
    titleCentered
    onBack={props.handleClickBack}
    showFilter
    filtered={props.checkFilter}
    openFilterModal={props.openFilterModal}
  >
    <Box padding="16px">
      <StatusTab
        selectedStatus={props.filteredStatus}
        onClick={(status: EnumClaimRequestStatus) => props.handleChangeStatus(status)}
        hasCancel={false}
      />
      <HistoryContainer>
        {props.showNotFoundSearch ? (
          <Box>
            <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Typography variant="h3" color="Gray/Secondary Text">
                รายการค้นหา
              </Typography>
              <Typography variant="subtitle2" color="Gray/Primary Text">
                (0 รายการ)
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              position="fixed"
              top="40%"
              left="10%"
              right="10%"
            >
              <Typography variant="h3" color="Gray/Secondary Text">
                ไม่พบรายการที่ต้องการ
              </Typography>
              <Typography variant="h4" color="Text/Placeholder">
                กรุณาตรวจสอบข้อมูลตัวกรองที่ใช้ค้นหาอีกครั้ง
              </Typography>
            </Box>
          </Box>
        ) : (
          <InfiniteScroll
            dataLength={props.claimRequestList.length ?? 0}
            next={props.fetchMoreData}
            hasMore={props.hasMore}
            loader={<Loader />}
          >
            {props.claimRequestList.map((history: any) => (
              <ClaimRequestHistoryItem
                key={history.id}
                id={history.id}
                info={history.info}
                employeeGroup={history.employee.title}
                department={history.employee.department}
                workflowSeq={history.workflowSeq}
                createdAt={history.createdAt}
                referenceId={history.referenceId}
                amount={props.requestAmount(history)}
                onClick={(id: string) => props.handleClickHistoryItem(id)}
                owner={history.employee}
                createdBy={history.createdBy}
                userRole={props.currentUser.role}
                status={history.status}
                filteredStatus={props.filteredStatus}
              />
            ))}
          </InfiniteScroll>
        )}
      </HistoryContainer>
    </Box>
  </PageContent>
)

const API = {
  GET_CLAIM_REQUEST_LIST: gql`
    query GET_CLAIM_REQUEST_LIST($status: String!, $filters: JSON, $pagination: JSON) {
      claimRequestList(input: { status: $status, filters: $filters, pagination: $pagination }) {
        workflowSeq
        id
        type
        status
        config
        info
        employee
        createdAt
        createdBy
        referenceId
      }
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo, values, setValues } = props
    const { useQuery, useEffect, useCallback, useMemo, useState } = hooks
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])
    const [filteredStatus, setFilteredStatus] = useState(EnumClaimRequestStatus.WAITING)
    const { data, refetch, fetchMore } = useQuery(API.GET_CLAIM_REQUEST_LIST, {
      variables: { status: filteredStatus, pagination: { offset: 0, limit: 10 } },
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        if (data.claimRequestList.length === 0) setHasMore(false)
      },
    })
    const [canSubmit, setCanSubmit] = useState(false)
    const [hasFilter, setHasFilter] = useState(false)
    const [hasMore, setHasMore] = useState(true)

    const handleChangeStatus = useCallback((status: EnumClaimRequestStatus) => {
      setFilteredStatus(status)
      setHasMore(true)
    }, [])
    const requestAmount = useCallback((history: any) => {
      let amount = 0
      history.info.inputs.forEach((input: any) => {
        if (input.name === history.info.amountFieldName) {
          amount = input.value
        }
      })
      return amount
    }, [])

    const handleClickHistoryItem = useCallback((id: string) => {
      paths.approvalRequestPath(id).push()
    }, [])

    const handleClickBack = useCallback(() => {
      paths.landingPath().push()
    }, [])

    const fetchMoreData = useCallback(() => {
      fetchMore({
        variables: {
          status: filteredStatus,
          filters: values,
          pagination: {
            offset: data?.claimRequestList.length,
            limit: 10,
          },
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (fetchMoreResult.claimRequestList) setHasMore(fetchMoreResult.claimRequestList.length > 0)
          if (!fetchMoreResult) return prev
          return {
            claimRequestList: [...prev.claimRequestList, ...fetchMoreResult.claimRequestList],
          }
        },
      })
    }, [data, fetchMore, values, filteredStatus])

    const openFilterModal = useCallback(() => {
      // @ts-ignore
      Modal.open({
        className: "FilterModal",
        children: <FilterClaimRequests setOutsideFormValues={setValues} filterValues={values} />,
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        okButtonLabel: "ยืนยัน",
        okButtonVariant: "contained",
        onOk: async ({ close }: any) => {
          setCanSubmit(true)
        },
      })
    }, [values, setValues])

    useEffect(() => {
      if (canSubmit) {
        const fetchData = async () => {
          await refetch({ filters: values })
          if (!isEmpty(values)) setHasFilter(true)
          if (every(values, (value) => !value)) setHasFilter(false)
          setCanSubmit(false)
          // @ts-ignore
          Modal.close()
        }
        fetchData()
      }
    }, [values, refetch, canSubmit])
    const checkFilter = useMemo(() => {
      for (const value of Object.values(values)) {
        if (value && value !== "all") {
          return true
        }
      }
      return false
    }, [values])
    const showNotFoundSearch = useMemo(() => data?.claimRequestList.length === 0 && hasFilter, [data, hasFilter])

    return {
      handleChangeStatus,
      claimRequestList: data?.claimRequestList || [],
      requestAmount,
      handleClickHistoryItem,
      filteredStatus,
      handleClickBack,
      currentUser,
      openFilterModal,
      hasFilter,
      showNotFoundSearch,
      fetchMoreData,
      hasMore,
      checkFilter,
    }
  }),
)

const ClaimRequestPage = enhancer(ClaimRequestsPageComponent)

export default ClaimRequestPage
