import { Box, Button, Divider, Grid, Hidden, Icon, Modal, MuiIcon, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import ClaimConditions from "components/advance/ClaimConditions"
import ClaimRemarks from "components/advance/ClaimRemarks"
import { RequiredDocumentCard } from "components/advance/RequiredDocumentCard"
import { DocumentConfig, UploadDocumentCard } from "components/advance/UploadDocumentCard"

import { INPUT_TYPE } from "constants/enums/input-type"
import { OptionValue } from "constants/enums/option-value"
import { Option } from "constants/interface/options"
import dayjs from "dayjs"
import { compose, withFormik, withHooks, withStores } from "enhancers"

import { Card, CardContent } from "@material-ui/core"
import { ReactComponent as Point } from "assets/icon/point.svg"
import { PageContent } from "layouts"
import { get, isEmpty, isNil, uniqBy } from "lodash"
import styled from "styled-components"
import Theme from "theme/custom"
import { employeeIsHr, gql, isJson, paths, toCurrency } from "utils/helper"
import SuccessPage from "./PageSuccess"
import { AutoCalMethods } from "./autoCal"
import { Field } from "./form-field"
import { useHistory } from "react-router-dom"

const CustomIconList = styled(MuiIcon)`
  font-size: 20px;
  margin-left: 8px;
`

const ContentBox = styled(Box)`
  padding: 16px;
`
const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${Theme.colors["Warning"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const List = styled.ul`
  margin: 0px 0px 0px -16px;
`

const ClaimDetailPage = (props: any) => (
  <>
    {props.step !== 4 && (
      <PageContent
        title={props.titlePage}
        titleCentered
        onBack={props.handleClickBack}
        extendedTitleHeight="100px"
        overlapHeight="70px"
        type="primary"
      >
        <ContentBox>
          {props.hrInstead && props.currentUserIsHr && props.isPreview && (
            <WarningBox mb={props.step === 4 ? "14px" : "16px"}>
              <Icon name="Warning" color={Theme.colors["Text/Primary Text"]} width={"16px"} height={"16px"}></Icon>
              <Typography variant="body2" color={Theme.colors["Text/Primary Text"]}>
                ทำรายการแทนพนักงานคนอื่น
              </Typography>
            </WarningBox>
          )}
          <ClaimCard
            title={props.title}
            type={props.type}
            name={props.name}
            approvedRequest={props.approvedCount}
            totalAmount={props.approvedAmount}
            icon={props.icon}
            iconColor={props.iconColor}
            chipTitleColor={props.chipTitleColor}
            chipBackgroundColor={props.chipBackgroundColor}
            fullSize
            displayOnly
          />
          {!props.isPreview && (
            <>
              <ClaimConditions conditions={props.conditions} />
              <RequiredDocumentCard documents={props.documents} />
              <Hidden when={props.remark?.length === 0}>
                <Divider style={{ margin: "32px 0px 4px 0px", color: Theme.colors["Text/Primary Text"] }} />
              </Hidden>
              <ClaimRemarks remarks={props.remark} step={props.step} />
            </>
          )}
          {props.step === 1 && (
            <Button onClick={props.handleClickClaim} width="100%" variant="outlined" style={{ marginBottom: 24 }}>
              เบิกสวัสดิการนี้
              <CustomIconList name="ChevronRight" />
            </Button>
          )}

          {props.step !== 1 && (
            <>
              <Box
                mt={
                  props.isPreview
                    ? props.hrInstead && props.currentUserIsHr && props.remark?.length === 0
                      ? "16px"
                      : props.remark?.length === 0
                      ? "16px"
                      : "0px"
                    : "8px"
                }
              >
                <Typography variant="h3" color="Text/Black2">
                  {props.isPreview ? "ตรวจสอบรายละเอียด" : "กรอกรายละเอียดการเบิก"}
                </Typography>
              </Box>
              {props.hrInstead && props.currentUserIsHr && !props.isPreview && (
                <WarningBox mt="16px">
                  <Icon
                    style={{ marginRight: "4px" }}
                    name="Warning"
                    color={Theme.colors["Text/Primary Text"]}
                    width={16}
                    height={16}
                  />
                  <Typography variant="body2" color={Theme.colors["Text/Primary Text"]}>
                    ทำรายการแทนพนักงานคนอื่น
                  </Typography>
                </WarningBox>
              )}
              <Grid container spacing={0} style={{ paddingTop: 16, marginBottom: "40px" }}>
                <Grid item xs={12} key="select-employee">
                  <Box mt={props.isPreview ? "0px" : "8px"}>
                    <Field
                      isPreview={props.isPreview}
                      component="SELECT"
                      label="ผู้ขอเบิก"
                      name="requesterId"
                      options={props.employeeOptions}
                      value={props.requesterFullName}
                      disabled={!props.currentUserIsHr}
                      disableClearable
                      required
                    />
                  </Box>
                </Grid>
                {props.relationConfigs.isShow && (
                  <Grid item xs={12} key="select-relations">
                    <Box mt={props.isPreview ? "0px" : "8px"}>
                      {props.isPreview && props.claimForValue === "Self" ? (
                        <></>
                      ) : (
                        <Field
                          isPreview={props.isPreview}
                          component="SELECT"
                          label="เบิกให้"
                          name="claimFor"
                          options={props.relationConfigs.options}
                          value={props.claimForValue?.nameTh}
                        />
                      )}
                    </Box>
                  </Grid>
                )}

                {props.inputs.map((input: any, ind: number) => (
                  <>
                    <Grid item xs={12} key={`${input.name} ${ind}`} style={{ paddingTop: 16 }}>
                      <Box mt={props.isPreview ? "0px" : "8px"}>
                        <Field
                          isPreview={props.isPreview}
                          value={input.value}
                          component={input.type}
                          label={input.title}
                          name={input.name}
                          disabled={input.disabled}
                          options={props.getOptions(input.options, input.name)}
                          unit={input.unit}
                          required={input.required}
                          placeholder={input.placeholder}
                        />
                      </Box>
                    </Grid>
                    {props.hasIsOther(input.type, input.name) && (
                      <Grid item xs={12} key={`${input.name} ${ind}_is_other`} style={{ paddingTop: 16 }}>
                        <Typography variant="body1" color={props.isPreview ? "Text/Gray Preview" : "Text/Black2"}>
                          {`กรอก ${input.title} อื่นๆ`}
                        </Typography>
                        <Box mt={props.isPreview ? "0px" : "8px"}>
                          <Field component={INPUT_TYPE.TEXT} label="" name={input.name + "_is_other"} />
                        </Box>
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>

              {props.showUploadFile && (
                <UploadDocumentCard
                  documents={props.documents}
                  onChange={props.handleFilesChange}
                  canDelete={!props.isPreview}
                  isPreview={props.isPreview}
                  onUploading={props.handleUploading}
                  onUploaded={props.handleUploaded}
                />
              )}

              {props.isPreview ? (
                <>
                  <div style={{ marginBottom: "40px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "16px" }}>
                      <Point></Point>
                      <Typography variant="h3" color="black">
                        สรุปการใช้คะแนน
                      </Typography>
                    </div>
                    <Card style={{ boxShadow: " 0 0 1px" }}>
                      <CardContent style={{ padding: "16px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
                          <Typography variant="h4" color={Theme.colors["Text/Primary Text"]}>
                            คะแนนที่ใช้
                          </Typography>
                          <Typography variant="Body/32" color="black" isCurrency>
                            {props.amount} คะแนน
                          </Typography>
                        </div>
                        <div style={{ marginTop: "8px" }} />
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <Typography variant="h4" color="black" isCurrency>
                            คิดเป็นเงิน {props.amount} บาท
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                  <Button
                    onClick={props.handleClickSendRequest}
                    style={{ marginBottom: 8 }}
                    width={`100%`}
                    variant="contained"
                  >
                    ยื่นคำขอ
                  </Button>
                </>
              ) : (
                <Button
                  disabled={props.isUploading}
                  onClick={props.handleSubmit}
                  style={{ marginBottom: 8 }}
                  width={`100%`}
                  variant="outlined"
                >
                  ถัดไป
                  <CustomIconList name="ChevronRight" />
                </Button>
              )}
            </>
          )}
        </ContentBox>
      </PageContent>
    )}
    {props.step === 4 && (
      <PageContent
        title={props.titlePage}
        titleCentered
        onBack={props.handleClickBack}
        extendedTitleHeight="300px"
        overlapHeight="300px"
        type="linear"
      >
        <SuccessPage
          isRepresent={props.isRepresent}
          requester={props.requesterFullName}
          requestName={props.title}
          type={props.type}
          amount={props.amount}
          referenceId={props.requestResponse.referenceId}
          createdDate={props.requestResponse.createdAt}
          icon={props.icon}
          iconColor={props.iconColor}
          chipTitleColor={props.chipTitleColor}
          chipBackgroundColor={props.chipBackgroundColor}
          name={props.name}
          currentUserIsHr={props.currentUserIsHr}
          hrInstead={props.hrInstead}
        />
      </PageContent>
    )}
  </>
)

const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS($employeeId: String!) {
      claimRequestConfigs(employeeId: $employeeId) {
        usage
      }
    }
  `,
  CREATE_CLAIM_REQUEST: gql`
    mutation CREATE_CLAIM_REQUEST(
      $type: String!
      $config: JSON!
      $info: JSON!
      $employeeId: String!
      $createdBy: String!
    ) {
      createClaimRequest(
        input: { type: $type, config: $config, info: $info, employeeId: $employeeId, createdBy: $createdBy }
      ) {
        id
        referenceId
        createdAt
      }
    }
  `,
  GET_EMPLOYEES: gql`
    query GET_EMPLOYEES {
      getEmployees {
        id
        employeeCode
        firstName
        lastName
        role
        grade
        employmentStartDate
        employmentType
        probationPeriod
        probationStatus
        functionalDesignation
      }
    }
  `,
  VALIDATE_REQUEST: gql`
    mutation VALIDATE_REQUEST($info: JSON!, $employeeId: String!) {
      validateClaim(inputs: { info: $info, employeeId: $employeeId }) {
        hasErrorMessage
      }
    }
  `,
  GET_DEPENDENTS: gql`
    query GET_DEPENDENTS($relationships: [String!]!) {
      userDependents(input: { relationships: $relationships }) {
        nameTh
        relationship
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
        }
      }
    }
  `,
}

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { setFieldValue, values, handleSubmit, currentUserInfo, dirty } = props
    const {
      useHandleSubmit,
      useState,
      useMemo,
      useMutation,
      useQuery,
      useLazyQuery,
      useCallback,
      useEffect,
      usePrevious,
    } = hooks
    const history = useHistory()
    const selectedRequestStorage = useMemo(() => JSON.parse(localStorage.getItem("selected_request") ?? ""), [])
    const [selectedRequest, setSelectedRequest] = useState(selectedRequestStorage)
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])
    const [refetchConfig, { data: configs }] = useLazyQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      onCompleted: (data: any) => {
        const current = data.claimRequestConfigs.usage.find((res: any) => res.name === selectedRequest.name)
        setSelectedRequest({
          ...selectedRequest,
          approvedAmount: current?.approvedAmount || 0,
          approvedCount: current?.approvedCount || 0,
        })
      },
      fetchPolicy: "network-only",
    })
    const [validateRequest] = useMutation(API.VALIDATE_REQUEST)
    const { data: dependents } = useQuery(API.GET_DEPENDENTS, {
      variables: { relationships: selectedRequest.relations || [] },
    })

    const master = useMemo(() => {
      return JSON.parse(localStorage.getItem("master") ?? "")
    }, [])

    const workflowConfigs = useMemo(() => JSON.parse(localStorage.getItem("workflow") ?? ""), [])

    const {
      name, // request name
      title, // display request name
      icon, // mui v4 icon
      type, // request type
      iconColor, // hex color code
      chipTitleColor, // hex color code
      chipBackgroundColor, // hex color code
      conditions,
      inputs,
      documents,
      remark,
      amountFieldName,
      approvedCount,
      approvedAmount,
      section,
    } = selectedRequest

    const initialDocumentWithValue: DocumentConfig[] = useMemo(
      () => documents.map((doc: any) => ({ ...doc, file: "" })),
      [documents],
    )

    const { data: employeesRes } = useQuery(API.GET_EMPLOYEES)

    const [createClaimRequest] = useMutation(API.CREATE_CLAIM_REQUEST, {
      refetchQueries: [{ query: API.GET_CURRENT_USER }],
    })

    const [inputsWithValue, setInputsWithValue] = useState([])
    const [documentWithValue, setDocumentWithValue] = useState(initialDocumentWithValue)
    const [isPreview, setIsPreview] = useState(false)
    const [requester, setRequester] = useState()

    const [stepPage, setStepPage] = useState(1)
    const [requestResponse, setRequestResponse] = useState()
    const [isUploading, setIsUploading] = useState(false)

    const getStringValue = useCallback((type: INPUT_TYPE, formName: string, values: any) => {
      const value = values[formName]
      if (!value) return undefined
      switch (type) {
        case INPUT_TYPE.TIME_PICKER:
          return dayjs(value).format("HH:mm")
        case INPUT_TYPE.DATE:
          return dayjs(value).format("DD/MM/YYYY")
        case INPUT_TYPE.NUMBER:
        case INPUT_TYPE.CURRENCY:
          return toCurrency(value, { minimumFractionDigits: 0 })
        case INPUT_TYPE.SELECT:
          return value === OptionValue.IS_OTHER ? values[formName + "_is_other"] : values[formName]
        default:
          return value.toString()
      }
    }, [])

    const employeeDataDic = useMemo(() => {
      if (employeesRes) {
        let dataDic: any = {}
        employeesRes.getEmployees.forEach((employee: any) => {
          dataDic[employee.id] = employee
        })
        return dataDic
      }
      return undefined
    }, [employeesRes])

    const userWorkflow = useMemo(() => {
      if (requester) return workflowConfigs.find((workflow: any) => workflow.role === requester?.role)
      return []
    }, [workflowConfigs, requester])

    useHandleSubmit(
      async (values: any) => {
        if (isUploading) {
          return
        } // Prevent form submit when document is uploading.

        const initInputValues = inputs.map((input: any) => ({
          ...input,
          value: getStringValue(input.type, input.name, values),
        }))

        const info = {
          ...selectedRequest,
          inputs: initInputValues,
          documents: documentWithValue,
          workflow: userWorkflow,
        }
        if (dependents?.userDependents.length > 0) {
          info.claimFor = values.claimFor
        } else {
          info.claimFor = "Self"
        }

        const isValid = await handleValidateRequest(info, requester?.id)
        if (isValid) {
          setInputsWithValue(initInputValues)
          setRequester(requester)
          setIsPreview(true)
          setStepPage(3)
        }
      },
      [inputs, selectedRequest, documentWithValue, userWorkflow, isUploading],
    )

    const onValidateError = useCallback((error: any) => {
      // @ts-ignore
      Modal.alert({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box justifyContent="center" padding={4}>
              <MuiIcon fontSize="large" color={Theme.colors["Warning"]} name="Warning"></MuiIcon>
            </Box>
            <Box justifyContent="flexStart" mb="16px">
              <List style={{ listStyleType: JSON.parse(error.message).length < 2 ? "none" : "inherit" }}>
                {JSON.parse(error.message).map((mes: any, index: number) => (
                  <li key={1150}>
                    <Typography variant="body1" color="Text/Black2">
                      {mes}
                    </Typography>
                  </li>
                ))}
              </List>
            </Box>
          </Box>
        ),
        okButtonLabel: "ตกลง",
        okButtonVariant: "contained",
        buttonWidth: "100%",
      })
    }, [])

    const handleValidateRequest = useCallback(
      async (info: any, requesterId: string) => {
        try {
          await validateRequest({
            variables: { info, employeeId: requesterId },
          })
        } catch (error: any) {
          if (isJson(error.message)) {
            onValidateError(error)
          }
          return false
        }
        return true
      },
      [onValidateError, validateRequest],
    )

    const handleClickSendRequest = useCallback(async () => {
      try {
        const info = {
          ...selectedRequest,
          inputs: inputsWithValue,
          documents: documentWithValue,
          workflow: userWorkflow,
          claimFor: "Self",
        }
        if (dependents?.userDependents.length > 0) {
          info.claimFor = values.claimFor
        } else {
          info.claimFor = "Self"
        }
        const res = await createClaimRequest({
          variables: {
            type: selectedRequest.name,
            config: selectedRequest,
            info,
            employeeId: requester?.id,
            createdBy: currentUser.id,
          },
        })

        setRequestResponse(res.data.createClaimRequest)
        setStepPage(4)
      } catch (error: any) {
        if (isJson(error.message)) {
          onValidateError(error)
        }
      }
    }, [
      selectedRequest,
      inputsWithValue,
      documentWithValue,
      userWorkflow,
      dependents?.userDependents.length,
      createClaimRequest,
      requester?.id,
      currentUser.id,
      values.claimFor,
      onValidateError,
    ])

    const handleFilesChange = useCallback((documents: DocumentConfig[]) => {
      setDocumentWithValue(documents.sort((a, b) => a.seq - b.seq))
    }, [])

    const handleClickBack = useCallback(() => {
      switch (stepPage) {
        case 1:
          history.goBack()
          break
        case 4:
          paths.landingPath().push()
          break
        case 3:
          setIsPreview(false)
          setStepPage(stepPage - 1)
          break
        case 2:
          setStepPage(stepPage - 1)
          break
        default:
          setStepPage(stepPage - 1)
          break
      }
    }, [history, stepPage])

    const employeeOptions = useMemo(() => {
      if (employeesRes) {
        const employees = employeesRes.getEmployees
        return employees
          .filter((emp: any) => !employeeIsHr(emp.role) || emp.employeeCode === currentUser.employeeCode)
          .map((employee: any) => ({
            label: `${employee.firstName} ${employee.lastName}`,
            value: employee.id,
          }))
      }
      return []
    }, [employeesRes, currentUser])

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const isRepresent = useMemo(() => {
      return requester?.id !== currentUser.id
    }, [requester, currentUser])

    const showUploadFile = useMemo(() => {
      if (isPreview) return documentWithValue.filter((doc: any) => doc.file).length > 0
      return true
    }, [isPreview, documentWithValue])

    const getOptions = useCallback(
      (path: string, name?: string) => {
        if (path && !isEmpty(path)) {
          if (name === "district_departure_place" || name === "district_distances") {
            const provinceData = uniqBy(
              get(master, path.split(".")[1]).map((option: any) => {
                const [province, district] = option.label.split(" » ")
                return { label: district, value: district }
              }),
              "label",
            )
            return provinceData
          } else return get(master, path.split(".")[1]) as Option[]
        }
        return []
      },
      [master],
    )

    const hasIsOther = useCallback(
      (type: INPUT_TYPE, name: string) => {
        if (isPreview) return false
        if (type !== INPUT_TYPE.SELECT) return false
        return values[name] === OptionValue.IS_OTHER
      },
      [isPreview, values],
    )

    const handleClickClaim = useCallback(() => {
      setStepPage(2)
    }, [setStepPage])

    const handleUploading = useCallback(() => {
      setIsUploading(true)
    }, [])

    const handleUploaded = useCallback(() => {
      setIsUploading(false)
    }, [])

    const hrInstead = useMemo(() => currentUser.id !== requester?.id, [currentUser, requester])

    const autoCalConfigs = useMemo(() => {
      const fieldWithAutoCalMethods = inputs
        .map((input: any) => {
          const methodKey = `${name}__${input.name}`
          return { ...input, autoCalMethod: AutoCalMethods[methodKey as keyof typeof AutoCalMethods] }
        })
        .filter((input: any) => input.autoCal && !!input.autoCalMethod)
      return fieldWithAutoCalMethods
    }, [inputs, name])

    const canClaimForSelf = useMemo(() => !selectedRequest?.excludeSelfClaim, [selectedRequest?.excludeSelfClaim])

    const relationConfigs = useMemo(() => {
      const response = dependents?.userDependents || []
      const relation = {
        Child: "บุตร",
        Spouse: "คู่สมรส",
        MOM: "มารดา",
        DAD: "บิดา",
      }

      const familyOptions = response.map((dependent: any) => ({
        label: relation[dependent.relationship as keyof typeof relation] + " - " + dependent.nameTh,
        value: dependent,
      }))
      const selfOptions = { label: "ตัวเอง", value: "Self" }

      let options = [...familyOptions]
      if (canClaimForSelf) options = [selfOptions, ...options]
      return {
        isShow: options.length > 0,
        options,
      }
    }, [canClaimForSelf, dependents?.userDependents])

    const previousValues = usePrevious(values)

    useEffect(() => {
      if (values.requesterId && requester) {
        autoCalConfigs.forEach(({ name, autoCalMethod }: any) => {
          const value = autoCalMethod({ values, requester })
          const prev = previousValues && previousValues[name]
          const current = values && values[name]
          if (current === prev) {
            setFieldValue(name, value)
          }
        })
      }
    }, [autoCalConfigs, values, setFieldValue, previousValues, requester])

    useEffect(() => {
      if (currentUser && employeeDataDic) {
        if (isEmpty(values.requesterId)) setFieldValue("requesterId", currentUser.id)
        setRequester(employeeDataDic[currentUser.id])
      }
    }, [dirty, setFieldValue, currentUser, employeeDataDic, values.requesterId])

    useEffect(() => {
      if (employeeDataDic) {
        const selectedId = values["requesterId"]
        const selected = employeeDataDic[selectedId]
        if (selected) {
          setRequester(selected)
          refetchConfig({
            variables: { employeeId: selected.id },
          })
        }
      }
    }, [values, refetchConfig, employeeDataDic])

    useEffect(() => {
      if (isEmpty(values.claimFor) && canClaimForSelf) {
        setFieldValue("claimFor", "Self")
      }
    }, [setFieldValue, values.claimFor, canClaimForSelf, selectedRequest])

    useEffect(() => {
      if (values.province_departure_place) {
        const [province, district] = values.province_departure_place.split(" » ")

        const hasNestedField = !isNil(district)
        if (hasNestedField) {
          setFieldValue("province_departure_place", province)
          setFieldValue("district_departure_place", district)
        }
      }
    }, [values, setFieldValue])

    useEffect(() => {
      if (values.province_arrival_place) {
        const [province, district] = values.province_arrival_place.split(" » ")

        const hasNestedField = !isNil(district)
        if (hasNestedField) {
          setFieldValue("province_arrival_place", province)
          setFieldValue("dClaimDetailPageistrict_distances", district)
        }
      }
    }, [values, setFieldValue])

    return {
      titlePage: selectedRequest.title,
      title, // display request name
      icon, // mui v4 icon
      type, // request type
      iconColor, // hex color code
      chipTitleColor, // hex color code
      chipBackgroundColor, // hex color code

      conditions,
      inputs: isPreview ? inputsWithValue : inputs,
      documents: documentWithValue,
      remark,
      section,
      name,
      isPreview,
      handleSubmit,
      handleClickSendRequest,
      handleFilesChange,
      handleClickBack,
      handleUploading,
      handleUploaded,
      isUploading,

      employeeOptions,
      currentUserIsHr,
      requesterFullName: requester ? requester.firstName + " " + requester.lastName : undefined,
      isRepresent,
      amount: values[amountFieldName],
      showUploadFile,
      getOptions,
      hasIsOther,

      step: stepPage,
      approvedCount,
      approvedAmount,
      requestResponse,
      handleClickClaim,

      hrInstead,

      relationConfigs,
      claimForValue: values.claimFor,
    }
  }),
)

export default enhancer(ClaimDetailPage)
