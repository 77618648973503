import { Card, CardContent } from "@material-ui/core"
import { PinDropSharp } from "@material-ui/icons"
import { Box, Button, Divider, Grid, Typography, MuiIcon, Icon } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import dayjs from "dayjs"
import { compose, withFormik, withHooks } from "enhancers"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Theme from "theme/custom"
import { toCurrency } from "utils/helper"

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${Theme.colors["Warning"]};
  border-radius: 4px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const Container = styled.div<{
  row?: boolean
  height?: string
  width?: string
}>`
  background-color: ${Theme.colors["White / White"]};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; w100?: boolean; al: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: ${(props) => props.al};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  width: ${(props) => (props.w100 ? "100%" : "none")};
`

const SummaryItems = styled.div`
  margin-top: 16px;
  padding: 16px;
  background: ${Theme.colors["White / White"]};
  border: 0.5px solid ${Theme.colors["Gray/Flat Button Bg"]};
  box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
  border-radius: 8px;
`

const SummaryItem1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 0px 0px 8px 0px;
`

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 8px 0px 0px 0px;
`

const Categories = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  height: fit-content;
  min-width: fit-content;
  padding: 2px 4px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-right: 10px;
`

const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`

const SuccessPageComponent = (props: any) => (
  <div style={{ padding: 16 }}>
    {props.currentUserIsHr && props.hrInstead && (
      <>
        <WarningBox mt="8px" mb="24px">
          <Icon name="Warning" color={Theme.colors["Text/Primary Text"]} width={"16px"} height={"16px"}></Icon>
          <Typography variant="body2" color={Theme.colors["Text/Primary Text"]}>
            ทำรายการแทนพนักงานคนอื่น
          </Typography>
        </WarningBox>
      </>
    )}
    <Grid container>
      <Grid container>
        <ContainerFlex js="center" w100 al="center">
          <Typography variant="h3" color="White / White">
            ส่งเรื่องเบิกเรียบร้อยแล้ว
          </Typography>
          <Icon
            name="CheckCircleOutline"
            style={{ margin: "12px" }}
            color={Theme.colors["White / White"]}
            width={"56px"}
            height={"56px"}
          />
          <Typography variant="subtitle1" color="White / White">
            เลขทำรายการ : {props.referenceId}
          </Typography>
          <Typography variant="subtitle1" color="White / White">
            {dayjs(props.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </ContainerFlex>
        <Container height="76px" width="inherit">
          <ContainerFlex js="center" al="center">
            <Typography variant="body1" color="Text/Primary Text">
              เจ้าหน้าที่กำลังตรวจสอบคำขอเบิก
            </Typography>
            <Typography variant="body1" color="Text/Primary Text">
              เมื่อมีความคืบหน้าระบบจะแจ้งเตือนไปที่อีเมล
            </Typography>
            <Typography variant="body1" color="Text/Primary Text">
              ของคุณ
            </Typography>
          </ContainerFlex>
        </Container>
        <Typography variant="subtitle1" color="White / White">
          *สามารถเช็คสถานะได้ที่ “ประวัติการเบิก” หรือรอรับแจ้งเตือน บอกผลการยื่นคำขอ
        </Typography>
      </Grid>
    </Grid>
    <div style={{ marginTop: "80px", marginBottom: "40px" }}>
      <Link to="/home" style={{ textDecoration: "none" }}>
        <Button variant="contained" color={Theme.colors["White / White"]} width={`100%`}>
          <Icon
            name="HouseOutlined"
            color={Theme.colors["White / White"]}
            style={{ fontSize: "20px", marginRight: "8px" }}
          />
          กลับหน้าแรก
        </Button>
      </Link>
    </div>
    <ContainerFlex js="start" row w100 al="center">
      <MuiIcon name="DescriptionOutlined" style={{ marginRight: "12px", fontSize: "25px" }} />
      <Typography variant="h3" color="Text/Black2">
        รายละเอียดสวัสดิการที่ยื่นคําขอ
      </Typography>
    </ContainerFlex>
    <SummaryItems>
      <SummaryItem1>
        <ContainerFlex js="space-between" row al="center">
          <ContainerFlex js="" row al="center">
            <IconContainer>
              <IconBox>
                <Icon name={props.icon} color={props.iconColor} />
              </IconBox>
            </IconContainer>
            <Typography variant="body1" color="Text/Black2">
              {props.requestName}
            </Typography>
          </ContainerFlex>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "4px" }}>
            <Categories color={props.chipBackgroundColor}>
              <Typography variant="caption" color={props.chipTitleColor}>
                {props.type}
              </Typography>
            </Categories>
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Icon name="point" style={{ width: "10px", height: "10px" }} />
              <Typography variant="subtitle1">ตามจำนวนที่จ่าย</Typography>
            </div>
          </div>
        </ContainerFlex>
      </SummaryItem1>
      {props.amount !== undefined && (
        <>
          <Divider />
          <SummaryItem>
            <ContainerFlex js="space-between" row al="center">
              <Typography variant="subtitle1" color="Text/Gray Success">
                ยอดเบิก
              </Typography>
              <Typography variant="body2" color="Text/Black2" isCurrency>
                {props.amount} บาท
              </Typography>
            </ContainerFlex>
          </SummaryItem>
        </>
      )}
    </SummaryItems>
    <div style={{ marginBottom: "24px", marginTop: "32px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "16px" }}>
        <Icon name="point" />
        <Typography variant="h3" color="black">
          สรุปการใช้คะแนน
        </Typography>
      </div>
      <Card style={{ boxShadow: " 0 0 1px" }}>
        <CardContent style={{ padding: "16px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
            <Typography variant="h4" color={Theme.colors["Text/Primary Text"]}>
              คะแนนที่ใช้
            </Typography>
            <Typography variant="Body/32" color="black" isCurrency>
              {props.amount} คะแนน
            </Typography>
          </div>
          <div style={{ marginTop: "8px" }} />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="h4" color={Theme.colors["Text/Line"]} isCurrency>
              คิดเป็นเงิน {props.amount} บาท
            </Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  </div>
)

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const {
      isRepresent,
      amount,
      requestName,
      type,
      requester,
      icon,
      iconColor,
      chipTitleColor,
      chipBackgroundColor,
    } = props
    return {
      isRepresent,
      amount,
      requestName,
      type,
      requester,
      icon,
      iconColor,
      chipTitleColor,
      chipBackgroundColor,
      ...props,
    }
  }),
)

const SuccessPage = enhancer(SuccessPageComponent)

export default SuccessPage
