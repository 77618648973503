// import imageCompression from "browser-image-compression"
import imageCompression from "vendor/next-browser-image-compression/browser-image-compression.js"
import { Box } from "components"
import { Button, Icon, IconButton, ImageLightbox, Modal, MuiIcon, Typography } from "components/common"
import { MuiIconCollections } from "components/common/MuiIcon"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"

import styled from "styled-components"
import Theme from "theme/custom"
import { convertBase64ToFile, convertFileToBase64 } from "utils/helper"
import { v4 } from "uuid"
import { pdfToImages } from "./PdfConverter"
import Loader from "components/Loader"

import "./UploadDocumentCard.css"

const ItemContainer = styled.div`
  border: 1px solid ${Theme.colors["Gray/Flat Button Bg"]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: calc(100% - 32px);
  margin-top: 16px;
`

const ItemTitleContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; al: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: ${(props) => props.al};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
`

const IconContainer = styled.div`
  margin-top: 16px;
  background-color: ${Theme.colors["Gray/Bg"]};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 0px;
  border-radius: 8px;
`

const ItemImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
`

const CustomIconList = styled(MuiIcon)`
  font-size: 16px;
  margin-right: 6px;
`

const ImageTag = styled("img")`
  &:hover {
    cursor: pointer;
  }
`

const ImageContainer = styled.div`
  object-fit: contain;
  width: 200px;
  height: 200px;
`

const ImageRowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const Divider = styled.div`
  height: 16px;
  width: 100%;
  border-bottom: 1px solid ${Theme.colors["Text/Background"]};
  margin-bottom: 16px;
  display: "&nbsp";
`

export interface ImageInfo {
  url: string
  title: string
}

interface FileDataBase64 {
  id: string
  file: string
}

interface FileDataFile {
  id: string
  file: File
  fileImages?: ImageInfo[]
  width?: number
  height?: number
}

export interface DocumentConfig {
  seq: number
  name?: string
  title: string
  iconName: MuiIconCollections
  remark: string
  required?: boolean
  amountLimit?: number
  file?: FileDataBase64[]
  isPreview?: boolean
  maxSizeInMB?: number
}

export interface DocumentItemProps extends DocumentConfig {
  canDelete: boolean
  onChange: (files: FileDataFile[], seq: number) => void
  onUploading: (seq: number) => void
  onUploaded: (seq: number) => void
  hideLoader?: boolean
}

const TempLightboxBackground = () => (
  <div className="temp-lb-container lb-container">
    <div className="lb-header" />
  </div>
)

const NextButtonContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 50001;
`

const NextButton = ({ onClick = () => {} }: { onClick?: () => void }) => (
  <NextButtonContainer onClick={onClick}>
    <Icon width={48} height={48} name="KeyboardArrowRight" color="#ffffff" />
  </NextButtonContainer>
)

const PreviousButtonContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 50001;
`

const PreviousButton = ({ onClick = () => {} }: { onClick?: () => void }) => (
  <PreviousButtonContainer onClick={onClick}>
    <Icon width={48} height={48} name="KeyboardArrowLeft" color="#ffffff" />
  </PreviousButtonContainer>
)

// Function for get width and height of image file
async function getImageSize(file: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
    img.onerror = (error) => {
      reject(error)
    }
    img.src = URL.createObjectURL(file)
  })
}

async function convertImageUrlToFile(imageUrl: string, fileName: string): Promise<File> {
  const response = await fetch(imageUrl)
  const blob = await response.blob()
  return new File([blob], fileName)
}

async function processFile(fileProps: File[], maxSizeInMB: number): Promise<FileDataFile> {
  const file: File = fileProps[0]
  let fileObj: FileDataFile = { id: v4(), file }

  if (file.type === "application/pdf") {
    fileObj.fileImages = await pdfToImages(file)
    const { width, height } = await getImageSize(
      await convertImageUrlToFile(fileObj.fileImages[0].url, "pdf-image.jpg"),
    )
    fileObj.width = width
    fileObj.height = height
  } else {
    const maxSizeMB = maxSizeInMB
    const compressedFile = await imageCompression(file, {
      maxSizeMB,
      useWebWorker: true,
      preserveExif: true,
      libURL: "https://cdn.jsdelivr.net/npm/browser-image-compression@2.0.2/dist/browser-image-compression.js",
    })
    fileObj.file = compressedFile
    const { width, height } = await getImageSize(compressedFile)
    fileObj.width = width
    fileObj.height = height
    // fileObj.fileImages = [
    //   {
    //     url: URL.createObjectURL(compressedFile),
    //     title: compressedFile.name,
    //   },
    // ]
  }

  return fileObj
}

const DocumentItem = (props: DocumentItemProps) => {
  const {
    amountLimit,
    iconName,
    name,
    remark,
    seq,
    title,
    file: initialFiles = [],
    onChange,
    onUploading,
    onUploaded,
    canDelete,
    isPreview,
    required,
    maxSizeInMB = 1,
    hideLoader,
  } = props

  const isShowLoader = useMemo(() => {
    return hideLoader === undefined || hideLoader === null ? true : !hideLoader
  }, [hideLoader])

  const initState = useRef<FileDataFile[]>([])
  const [isInitStateUpdated, setIsInitStateUpdated] = useState(false)

  useEffect(() => {
    if (initialFiles.length === 0) {
      setIsInitStateUpdated(true)
    } else {
      const prepareInitData = async () => {
        const initData: FileDataFile[] = []
        for (const { id, file: base64File } of initialFiles) {
          const file = convertBase64ToFile(base64File)
          if (file.type === "application/pdf") {
            const fileObj = await processFile([file], maxSizeInMB)
            initData.push({
              id,
              file: fileObj.file,
              fileImages: fileObj.fileImages,
            })
          } else {
            const { width, height } = await getImageSize(file)
            initData.push({
              id,
              file,
              width,
              height,
            })
          }
        }

        initState.current = initData
        setIsInitStateUpdated(true)
      }

      prepareInitData()
    }
  }, [initialFiles, maxSizeInMB])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [files, setFiles] = useState<FileDataFile[]>(initState.current)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [currentOpenFileIndex, setCurrentOpenFileIndex] = useState(-1)

  const filesCount = useMemo(() => files.length, [files])

  useEffect(() => {
    if (isInitStateUpdated) {
      setFiles(initState.current)
    }
  }, [isInitStateUpdated])

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: [".png", ".jpg", ".pdf", ".jpeg"],
    multiple: false,
    // maxSize: 1048576,
    onDropAccepted: (fileProps: File[]) => {
      const handleDrop = async () => {
        const loadingTimeout = setTimeout(() => setIsLoading(true), 500) // Start the timer
        onUploading(seq)
        // const maxSizeInbytes = maxSizeInMB * 1048576
        try {
          const fileObj = await processFile(fileProps, maxSizeInMB)
          onUploaded(seq)
          onChange([...files, fileObj], seq)
          setFiles([...files, fileObj])
        } catch (error) {
          console.error(error)
        } finally {
          clearTimeout(loadingTimeout)
          setIsLoading(false)
        }
      }

      handleDrop()
    },
  })

  const previewImage = (file: any) => {
    setIsLightboxOpen(true)
    // @ts-ignore
    ImageLightbox.open({
      image: URL.createObjectURL(file),
      onClose: () => {
        setIsLightboxOpen(false)
      },
    })
  }

  const previewFileIndex = useCallback(
    (fileIndex: number) => {
      const targetFile = files[fileIndex]
      setIsLightboxOpen(true)
      setCurrentOpenFileIndex(fileIndex)
      // @ts-ignore
      ImageLightbox.close()
      switch (targetFile.file.type) {
        case "application/pdf":
          document.body.classList.remove("current-show-single-image-lightbox")

          setTimeout(() => {
            document.body.classList.remove("on-switch-image-lightbox")
            // @ts-ignore
            ImageLightbox.open({
              images: targetFile.fileImages,
              onClose: () => {
                setCurrentOpenFileIndex(-1)
                setIsLightboxOpen(false)
              },
            })
          }, 100)
          break
        case "image/jpeg":
        case "image/png":
          document.body.classList.add("current-show-single-image-lightbox")
          setTimeout(() => {
            document.body.classList.remove("on-switch-image-lightbox")
            // @ts-ignore
            ImageLightbox.open({
              image: URL.createObjectURL(targetFile.file),
              // images: [URL.createObjectURL(targetFile.file)],
              onClose: () => {
                document.body.classList.remove("current-show-single-image-lightbox")
                setCurrentOpenFileIndex(-1)
                setIsLightboxOpen(false)
              },
            })
          }, 100)
          break
        default:
          break
      }
    },
    [files],
  )

  const isCurrentFirstAttachmentOpen = useMemo(() => {
    return currentOpenFileIndex === 0
  }, [currentOpenFileIndex])

  const isCurrentLastAttachmentOpen = useMemo(() => {
    return currentOpenFileIndex === filesCount - 1
  }, [currentOpenFileIndex, filesCount])

  const gotoNextAttachment = useCallback(() => {
    if (!isCurrentLastAttachmentOpen) {
      document.body.classList.add("on-switch-image-lightbox")
      previewFileIndex(currentOpenFileIndex + 1)
    }
  }, [currentOpenFileIndex, isCurrentLastAttachmentOpen, previewFileIndex])

  const gotoPreviousAttachment = useCallback(() => {
    if (!isCurrentFirstAttachmentOpen) {
      document.body.classList.add("on-switch-image-lightbox")
      previewFileIndex(currentOpenFileIndex - 1)
    }
  }, [currentOpenFileIndex, isCurrentFirstAttachmentOpen, previewFileIndex])

  const isShowPreviousButton = useMemo(() => filesCount > 1 && isLightboxOpen && !isCurrentFirstAttachmentOpen, [
    filesCount,
    isLightboxOpen,
    isCurrentFirstAttachmentOpen,
  ])

  const isShowNextButton = useMemo(() => filesCount > 1 && isLightboxOpen && !isCurrentLastAttachmentOpen, [
    filesCount,
    isLightboxOpen,
    isCurrentLastAttachmentOpen,
  ])

  const handleRemoveFile = useCallback(
    (confirmProps: any, id: string) => {
      confirmProps.close()
      const filtered = files.filter((file) => file.id !== id)
      setFiles(filtered)
      onChange(filtered, seq)
    },
    [files, onChange, seq],
  )

  const onClickRemoveFile = useCallback(
    (id: string) => {
      // @ts-ignore
      Modal.confirm({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box mb="16px">
              <MuiIcon fontSize="large" color={Theme.colors["Error/Error Text"]} name="DeleteOutline"></MuiIcon>
            </Box>
            <Box mb="8px">
              <Typography variant="h3" color={Theme.colors["Text/Primary Text"]}>
                ลบไฟล์
              </Typography>
            </Box>
            <Box mb="16px">
              <Typography variant="body1" color={Theme.colors["Text/Primary Text"]}>
                คุณยืนยันที่จะลบหรือไม่
              </Typography>
            </Box>
          </Box>
        ),
        okButtonLabel: "ยืนยันลบ",
        okButtonVariant: "outlined",
        cancelButtonLabel: "ยกเลิก",
        cancelButtonVariant: "contained",
        buttonWidth: 147,
        swapButton: true,
        onOk: (props: any) => handleRemoveFile(props, id),
      })
    },
    [handleRemoveFile],
  )

  const fileComponent = useMemo(() => {
    return files.map(({ id, file, fileImages }, fileIndex: number) => {
      return (
        <>
          <ImageRowContainer
            style={{ position: "relative", overflow: "hidden", backgroundColor: Theme.colors["Text/Background"] }}
            key={id}
          >
            <ImageContainer style={{ width: "fit-content" }}>
              {file.type === "application/pdf" && (
                <ImageTag
                  height={200}
                  src={fileImages?.[0].url}
                  alt={file.name}
                  onClick={() => previewFileIndex(fileIndex)}
                />
              )}
              {file.type !== "application/pdf" && (
                <ImageTag
                  height={200}
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  onClick={() => previewFileIndex(fileIndex)}
                />
              )}
            </ImageContainer>
            {!isPreview && (
              <IconButton onClick={() => onClickRemoveFile(id)} style={{ top: 0, right: 0, position: "absolute" }}>
                <MuiIcon color={Theme.colors["Error/Error Text"]} name="Delete" />
              </IconButton>
            )}
          </ImageRowContainer>
          {files.length > 1 && <Divider />}
        </>
      )
    })
  }, [files, isPreview, onClickRemoveFile, previewFileIndex])

  return (
    <ItemContainer>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
      </div>
      <ItemTitleContainer>
        <ContainerFlex js="" al="start" row>
          <Box mr={2}>
            <Typography variant="h4">{`${seq}. `}</Typography>
          </Box>
          <ContainerFlex js="" al="start">
            <Typography variant="h4" color="Text/Black2">
              {title}
              {required ? "*" : ""}
            </Typography>
            <Typography variant="subtitle1" color={Theme.colors["Text/Secondary Text"]}>
              (รองรับไฟล์ jpg, png, pdf)
            </Typography>
            <Box mt={1}>
              <Typography variant="subtitle1" color={Theme.colors["Text/Secondary Text"]}>
                {remark ? `(${remark})` : ""}
              </Typography>
            </Box>
          </ContainerFlex>
        </ContainerFlex>
        <Box ml={1}>
          <ContainerFlex js="" al="end" row>
            {!props.isPreview && (
              <Button
                type="button"
                onClick={open}
                style={{ width: "101px" }}
                color={Theme.colors["Primary/Primary Text"]}
                variant="outlined"
              >
                <CustomIconList name="Add" />
                เพิ่มไฟล์
              </Button>
            )}
          </ContainerFlex>
        </Box>
      </ItemTitleContainer>

      {files.length === 0 && !isLoading && isInitStateUpdated && (
        <IconContainer>
          {<MuiIcon name="Description" color={Theme.colors["Text/Gray Success"]} style={{ fontSize: "60px" }} />}
        </IconContainer>
      )}

      {files.length > 0 && (
        <Box display="flex" justifyContent="center">
          <ItemImageContainer>{fileComponent}</ItemImageContainer>
        </Box>
      )}

      {isShowLoader && isLoading && (
        <Box display="flex" justifyContent="center">
          <Loader />
        </Box>
      )}

      {isShowNextButton && <NextButton onClick={gotoNextAttachment} />}
      {isShowPreviousButton && <PreviousButton onClick={gotoPreviousAttachment} />}
    </ItemContainer>
  )
}

const Container = styled.div<{ mb?: string }>`
  margin-top: 40px;
  margin-bottom: ${(props) => (props.mb ? props.mb : "40px")};
`

const Items = styled.div`
  background-color: ${Theme.colors["White / White"]};
`

export interface UploadDocumentCardProps {
  documents: DocumentConfig[]
  onChange: (documents: DocumentConfig[]) => void
  onUploading?: () => void
  onUploaded?: () => void
  canDelete?: boolean
  isPreview?: boolean
  maxSizeInMB?: number
  mb?: string
  hideLoader?: boolean
}

export const UploadDocumentCard = (props: UploadDocumentCardProps) => {
  const {
    documents,
    onChange,
    canDelete = true,
    isPreview = false,
    maxSizeInMB = 1,
    mb,
    onUploading = () => {},
    onUploaded = () => {},
    hideLoader = false,
  } = props

  const [uploadingCount, setUploadingCount] = useState<number>(0)
  const [uploadedCount, setUploadedCount] = useState<number>(0)

  const handleChangeFile = useCallback(
    async (values: FileDataFile[], seq: number) => {
      const current = documents.find((doc) => doc.seq === seq)
      const others = documents.filter((doc) => doc.seq !== seq)

      const saveFiles = []
      for (const { id, file } of values) {
        saveFiles.push({
          id,
          file: await convertFileToBase64(file),
        })
      }

      if (current) {
        const saveItem: DocumentConfig = { ...current, file: saveFiles.length > 0 ? saveFiles : undefined }
        onChange([...others, saveItem])
      }
    },
    [documents, onChange],
  )

  const isShowItem = (hasFile?: boolean) => {
    if (isPreview) return hasFile

    return true
  }

  const handleUploading = useCallback(
    (seq: number) => {
      setUploadingCount((u) => u + 1)
    },
    [setUploadingCount],
  )

  const handleUploaded = useCallback(
    (seq: number) => {
      setUploadedCount((u) => u + 1)
    },
    [setUploadedCount],
  )

  useEffect(() => {
    // Trigger UploadDocumentCard on uploading or uploaded
    if (uploadingCount === uploadedCount) {
      onUploaded()
    } else {
      onUploading()
    }
  }, [uploadingCount, uploadedCount, onUploading, onUploaded])

  return (
    <Container mb={mb}>
      {documents.length > 0 && (
        <ContainerFlex js="" al="center" row>
          <Icon
            width={24}
            height={24}
            style={{ transform: "rotate(45deg)", marginRight: "10px" }}
            name="AttachFile"
            color={Theme.colors["Text/Primary Text"]}
          />
          <Typography variant="h3" color="Text/Black2">
            {isPreview ? "เอกสารแนบ" : "แนบเอกสาร"}
          </Typography>
        </ContainerFlex>
      )}
      <Items>
        {documents.map((doc, _index) => {
          if (isShowItem(doc.file ? doc.file.length > 0 : false)) {
            return (
              <DocumentItem
                key={`${doc.seq}-${doc.title}`}
                seq={doc.seq}
                title={doc.title}
                iconName={doc.iconName}
                remark={doc.remark}
                canDelete={canDelete}
                onChange={handleChangeFile}
                onUploading={handleUploading}
                onUploaded={handleUploaded}
                file={doc.file}
                required={doc.required}
                isPreview={isPreview}
                maxSizeInMB={maxSizeInMB}
                hideLoader={hideLoader}
              />
            )
          } else {
            return null
          }
        })}
      </Items>
      <TempLightboxBackground />
    </Container>
  )
}
