import { Box } from "components"
import { ClaimRequestHistoryItem } from "components/advance/ClaimRequestHistoryItem"
import { StatusTab } from "components/advance/StatusTab"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"

import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent } from "layouts"
import styled from "styled-components"
import Theme from "theme/custom"
import { gql, paths } from "utils/helper"

const HistoryContainer = styled.div`
  margin-top: 24px;
`

const HistoryComponent = (props: any) => (
  <PageContent title="ประวัติการเบิก" type="secondary" titleCentered onBack={props.handleClickBack}>
    <Box padding="16px">
      <StatusTab
        selectedStatus={props.selectedStatus}
        onClick={(status: EnumClaimRequestStatus) => props.handleChangeStatus(status)}
      />
      <HistoryContainer>
        {props.claimRequestHistory.map((history: any) => (
          <ClaimRequestHistoryItem
            key={history.id}
            id={history.id}
            info={history.info}
            workflowSeq={history.workflowSeq}
            employeeGroup={history.employee.title}
            department={history.employee.department}
            createdAt={history.createdAt}
            referenceId={history.referenceId}
            amount={props.requestAmount(history)}
            onClick={(id: string) => props.handleClickHistoryItem(id)}
            owner={history.employee}
            createdBy={history.createdBy}
            userRole={props.currentUser.role}
            status={history.status}
          />
        ))}
      </HistoryContainer>
    </Box>
  </PageContent>
)

const API = {
  GET_CLAIM_REQUEST_HISTORY: gql`
    query GET_CLAIM_REQUEST_HISTORY($employeeId: String!, $status: String) {
      claimRequestHistory(input: { employeeId: $employeeId, status: $status }) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        createdBy
        referenceId
      }
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { useQuery, useCallback, useEffect, useMemo, useState } = hooks
    const { currentUserInfo } = props
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])
    const [status, setStatus] = useState(EnumClaimRequestStatus.WAITING)
    const { data, refetch } = useQuery(API.GET_CLAIM_REQUEST_HISTORY, {
      variables: { employeeId: currentUser.id, status: status },
    })

    const requestAmount = useCallback((history: any) => {
      let amount = 0
      history.info.inputs.forEach((input: any) => {
        if (input.name === history.info.amountFieldName) {
          amount = input.value
        }
      })
      return amount
    }, [])

    const handleClickHistoryItem = useCallback((id: string) => {
      paths.historyDetailPath(id).push()
    }, [])

    const handleClickBack = useCallback(() => {
      paths.landingPath().push()
    }, [])

    const isRepresent = useCallback(
      (requesterId: string) => {
        return requesterId !== currentUser.id
      },
      [currentUser.id],
    )

    const handleChangeStatus = useCallback((status: EnumClaimRequestStatus) => {
      setStatus(status)
    }, [])

    useEffect(() => {
      if (status) refetch()
    }, [refetch, status])

    return {
      claimRequestHistory: data?.claimRequestHistory || [],
      requestAmount,
      handleClickHistoryItem,
      handleClickBack,
      isRepresent,
      handleChangeStatus,
      selectedStatus: status,
      currentUser,
    }
  }),
)

export default enhancer(HistoryComponent)
