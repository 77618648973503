import { EmploymentType } from "constants/enums/employment-type"
import { ProbationStatus } from "constants/enums/probation-status"

const workAge = (date: any) => {
  const today = new Date()
  const startDate = new Date(date)
  const todayCount = Math.floor(today.getTime() / (3600 * 24 * 1000))
  const startDateCount = Math.floor(startDate.getTime() / (3600 * 24 * 1000))
  const daysDiff = todayCount - startDateCount
  return daysDiff / 365
}

const employeeLevel = (functionalDesignation: string) => {
  const employeeLevels: any = [
    "Officer",
    "Leader",
    "Assistant Supervisor",
    "Supervisor",
    "Senior Supervisor",
    "Assistant Manager",
    "Manager",
    "Senior Manager",
    "Assistant Chief Manager",
    "Chief Manager",
    "Senior Chief Manager",
    "Assistant Vice President",
    "Vice President",
    "Senior Vice President",
    "Executive Vice President - JPM",
    "Executive Vice President",
    "Senior Executive Officer",
    "Executive Officer - JPM",
    "Chief Operating Officer",
    "Chief Information Officer",
    "Chief Financial Officer",
    "Chief Executive Officer",
  ]

  return employeeLevels.indexOf(functionalDesignation)
}

const passedProbation = (status: ProbationStatus) => {
  return status === ProbationStatus.Confirmed
}

const isTemporary = (type: EmploymentType) => {
  return type === EmploymentType.Temporary
}

const isPermanent = (type: EmploymentType) => {
  return type === EmploymentType.Permanent
}

const weddingCongratulation__amount = (context: any) => {
  const { values, requester } = context
  const employmentType = requester.employmentType
  const employmentStartDate = requester.employmentStartDate
  const probationStatus = requester.probationStatus
  if (isTemporary(employmentType) && workAge(employmentStartDate) >= 1) return 1000
  else if (isPermanent(employmentType) && passedProbation(probationStatus) && workAge(employmentStartDate) < 1)
    return 1000
  else if (isPermanent(employmentType) && workAge(employmentStartDate) >= 1) return 2000
  else return 0
}

const childbirthCongratulation__amount = (context: any) => {
  const { values, requester } = context
  const employmentType = requester.employmentType
  const employmentStartDate = requester.employmentStartDate
  const probationStatus = requester.probationStatus
  if (isTemporary(employmentType) && workAge(employmentStartDate) >= 1) return 1000
  else if (isPermanent(employmentType) && passedProbation(probationStatus) && workAge(employmentStartDate) < 1)
    return 1000
  else if (isPermanent(employmentType) && workAge(employmentStartDate) >= 1) return 2000
  else return 0
}

const employeeFamilyPassingAway__amount = (context: any) => {
  const { values, requester } = context
  const employmentType = requester.employmentType
  const employmentStartDate = requester.employmentStartDate
  if (isTemporary(employmentType) && workAge(employmentStartDate) >= 1) return 3000
  else if (isPermanent(employmentType)) return 3000
  else return 0
}

const employeePassingAway__amount = (context: any) => {
  const { values, requester } = context
  const employmentType = requester.employmentType
  if (isTemporary(employmentType) || isPermanent(employmentType)) return 5000
  else return 0
}

const domestic__total_amount = (context: any) => {
  const { values, requester } = context
  const employmentType = requester.employmentType
  const corporateTitle = requester.functionalDesignation
  console.log("Officer", employeeLevel("Officer"))
  console.log(corporateTitle, employeeLevel(corporateTitle))
  console.log("Senior Chief Manager", employeeLevel("Senior Chief Manager"))
  console.log(
    employeeLevel("Officer") <= employeeLevel(corporateTitle) &&
      employeeLevel(corporateTitle) <= employeeLevel("Senior Chief Manager"),
  )
  if (isTemporary(employmentType)) return 260
  else if (
    isPermanent(employmentType) &&
    employeeLevel("Officer") <= employeeLevel(corporateTitle) &&
    employeeLevel(corporateTitle) <= employeeLevel("Senior Chief Manager")
  )
    return 260
  else if (
    isPermanent(employmentType) &&
    employeeLevel("Assistant Vice President") <= employeeLevel(corporateTitle) &&
    employeeLevel(corporateTitle) <= employeeLevel("Senior Vice President")
  )
    return 360
  else if (employeeLevel("Executive Vice President") <= employeeLevel(corporateTitle) || corporateTitle === "Director")
    return 600
  else return 0
}

const internationalTripAllowance__amount = (context: any) => {
  const { values, requester } = context
  const corporateTitle = requester.functionalDesignation
  if (
    employeeLevel("Officer") <= employeeLevel(corporateTitle) &&
    employeeLevel(corporateTitle) <= employeeLevel("Senior Vice President")
  )
    return 1300
  else if (employeeLevel("Executive Vice President") <= employeeLevel(corporateTitle)) return 1600
  else return 0
}

const firstTripAllowance__amount = (context: any) => {
  const { values, requester } = context
  const corporateTitle = requester.functionalDesignation
  if (
    employeeLevel("Officer") <= employeeLevel(corporateTitle) &&
    employeeLevel(corporateTitle) <= employeeLevel("Senior Chief Manager")
  )
    return 7000
  else if (
    employeeLevel("Assistant Vice President") <= employeeLevel(corporateTitle) &&
    employeeLevel(corporateTitle) <= employeeLevel("Senior Executive Officer")
  )
    return 10000
  else return 0
}

export const EasybuyAutoCalMethods = {
  weddingCongratulation__amount,
  childbirthCongratulation__amount,
  employeeFamilyPassingAway__amount,
  employeePassingAway__amount,
  domestic__total_amount,
  internationalTripAllowance__amount,
  firstTripAllowance__amount,
}
