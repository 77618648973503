import { Box, Field, Typography } from "components"
import { INPUT_TYPE } from "constants/enums/input-type"
import { mappingComponent } from "."

import Theme from "theme/custom"
import { PreviewField } from "./preview"

export interface CustomFieldProps {
  component: keyof typeof INPUT_TYPE
  name: string
  label: string
  options?: any[]
  isPreview?: boolean
  value?: string
  disabled?: boolean
  disableClearable?: boolean
  unit?: string
  required?: boolean
  placeholder?: string
}

export const CustomField = (props: CustomFieldProps) => {
  const {
    label,
    name,
    isPreview,
    value,
    component,
    options = [],
    disabled = false,
    disableClearable = true,
    unit,
    required = false,
    placeholder,
  } = props
  const components = mappingComponent(INPUT_TYPE[component])

  if (isPreview) return <PreviewField previewProps={{ label, value, component, unit }} />
  if (component)
    return (
      <>
        <Box display="flex">
          <Typography variant="body1">{label}</Typography>
          {required && (
            <Typography variant="body1" color={Theme.colors["Other/Danger"]}>
              *
            </Typography>
          )}
        </Box>

        <Box display={unit ? "flex" : ""}>
          <Field
            fast
            options={options}
            component={components}
            name={name}
            disabled={disabled}
            inputProps={{ style: { textAlign: unit ? "end" : "" } }}
            fullWidth
            freeSolo={name === "province_departure_place" || name === "province_arrival_place"}
            disableClearable={disableClearable}
            placeholder={placeholder}
            // required={required}
          />
          {unit && (
            <Box display="flex" alignItems="center" justifyContent="flex-end" ml={4} minWidth="27px">
              <Typography variant="h4" color="Text/Black2">
                {unit}
              </Typography>
            </Box>
          )}
        </Box>
      </>
    )
  return (
    <Typography variant="body1" color={Theme.colors["Other Light/Danger"]}>
      Component not found. Please contact admin to check settings
    </Typography>
  )
}
