import userStore from "stores/userStore"
import DefaultColors from "./default-colors"
import SCGCColors from "./scgc-colors"

const appColorConfigs = {
  default: DefaultColors,
  "บริษัทเอสซีจี เคมิคอลส์ จำกัด (มหาชน)": SCGCColors,
  SCGC: SCGCColors,
  "SCGC-Family": SCGCColors,
}

// const themeName = (() => {
//   console.log(userStore.state.currentUser?.department)
//   const colorConfigsName: keyof typeof appColorConfigs = "default"
//   return colorConfigsName
// })()

// const theme = {
//   colors: appColorConfigs[themeName].colors,
//   backgroundGradient: appColorConfigs[themeName].gradient,
// }

class CustomTheme {
  // private get themeName(): keyof typeof appColorConfigs {
  //   const userDepartment = userStore.state.currentUser?.employee?.department
  //   console.log("userDepartment =", userDepartment)
  //   const colorConfigsName = userDepartment ?? "default"
  //   // console.log(colorConfigsName)
  //   return colorConfigsName
  // }

  get colors() {
    const themeName = (): keyof typeof appColorConfigs => {
      // const userDepartment = userStore.state.currentUser?.employee?.department
      const themeName = localStorage.getItem("themeName")
      const userDepartment = JSON.parse(themeName as string)
      // console.log("themeName =", userDepartment)
      const colorConfigsName = userDepartment in appColorConfigs ? userDepartment : "default"
      // console.log(colorConfigsName)
      return colorConfigsName
    }
    return appColorConfigs[themeName()]?.colors || appColorConfigs.default.colors
  }

  get backgroundGradient() {
    const themeName = (): keyof typeof appColorConfigs => {
      // const userDepartment = userStore.state.currentUser?.employee?.department
      const themeName = localStorage.getItem("themeName")
      const userDepartment = JSON.parse(themeName as string)
      // console.log("backgroundGradient =", userDepartment)
      const colorConfigsName = userDepartment in appColorConfigs ? userDepartment : "default"
      // console.log(colorConfigsName)
      return colorConfigsName
    }

    return appColorConfigs[themeName()]?.gradient || appColorConfigs.default.gradient
  }
}

export default new CustomTheme()
// export default theme
