import * as MaterialIcon from "@material-ui/icons"
import { ReactComponent as AddIcon } from "assets/icon/cart-icon.svg"
import { ReactComponent as NotFoundIcon } from "assets/icon/error_outline.svg"
import React from "react"
import { CustomMuiIconProps } from "./MuiIcon"
import { ReactComponent as Point } from "assets/icon/point.svg"
import { ReactComponent as Report } from "assets/icon/report.svg"
import { ReactComponent as ExportFile } from "assets/icon/export_file.svg"
import { ReactComponent as AutoStories } from "assets/icon/using/auto_stories.svg"
import { ReactComponent as Computer } from "assets/icon/using/computer.svg"
import { ReactComponent as Dentistry } from "assets/icon/using/dentistry.svg"
import { ReactComponent as DeviceWareables } from "assets/icon/using/devices_wearables.svg"
import { ReactComponent as ElectricMeter } from "assets/icon/using/electric_meter.svg"
import { ReactComponent as Exercise } from "assets/icon/using/exercise.svg"
import { ReactComponent as EyeGlasses } from "assets/icon/using/eyeglasses.svg"
import { ReactComponent as Favorite } from "assets/icon/using/favorite.svg"
import { ReactComponent as MedicalServices } from "assets/icon/using/medical_services.svg"
import { ReactComponent as School } from "assets/icon/using/school.svg"
import { ReactComponent as SportsTennis } from "assets/icon/using/sports_tennis.svg"
import { ReactComponent as Vaccines } from "assets/icon/using/vaccines.svg"
import { ReactComponent as Home } from "assets/icon/home.svg"
import { ReactComponent as BasketBall } from "assets/icon/using/basketball.svg"
import { ReactComponent as Bike } from "assets/icon/using/bike.svg"
import { ReactComponent as Beauty } from "assets/icon/using/beauty.svg"
import { ReactComponent as Family } from "assets/icon/using/family.svg"
import { ReactComponent as Pet } from "assets/icon/using/pet.svg"
import { ReactComponent as WaterDrop } from "assets/icon/using/warterdrop.svg"
import { ReactComponent as Savings } from "assets/icon/using/savings.svg"
import { ReactComponent as Diversity } from "assets/icon/using/diversity.svg"
import { ReactComponent as FoldedHands } from "assets/icon/using/folded_hands.svg"
import { ReactComponent as PersonalInjury } from "assets/icon/using/personal_injury.svg"

type MuiIconCollections = keyof typeof MaterialIcon
export type IconName = keyof typeof SvgIcon | MuiIconCollections

interface IconProps extends Omit<CustomMuiIconProps, "name"> {
  name: IconName
}

export const SvgIcon = {
  point: Point,
  report: Report,
  export: ExportFile,
  auto_stories: AutoStories,
  computer: Computer,
  dentistry: Dentistry,
  devices_wearables: DeviceWareables,
  electric_meter: ElectricMeter,
  exercise: Exercise,
  eyeglasses: EyeGlasses,
  favorite: Favorite,
  medical_services: MedicalServices,
  school: School,
  sports_tennis: SportsTennis,
  vaccines: Vaccines,
  home: Home,
  basketball: BasketBall,
  bike: Bike,
  beauty: Beauty,
  family: Family,
  pet: Pet,
  waterdrop: WaterDrop,
  savings: Savings,
  diversity: Diversity,
  folded_hands: FoldedHands,
  personal_injury: PersonalInjury,
}

const IconComponent = ({ name, color, width = "20px", height = "20px", style, ...rest }: IconProps) => {
  if (name) {
    if (name in SvgIcon) {
      const Svg = SvgIcon[name as keyof typeof SvgIcon]
      return <Svg fill={color} width={width} height={height} {...rest} />
    }
    if (MaterialIcon[name as MuiIconCollections])
      return React.createElement(MaterialIcon[name as MuiIconCollections], {
        ...rest,
        htmlColor: color,
        style: {
          ...style,
          width,
          height,
        },
      })
  }

  return <NotFoundIcon />
}

export default IconComponent
