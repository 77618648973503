import { findKey } from "lodash"
import { isClient } from "common/helper"
import { cleanEnv, num, port, str, url } from "envalid"

const urlConfig = {
  production: {
    HOST: "https://reimbursement-app-flexben.scg.com",
    API_ENDPOINT: "https://reimbursement-app-flexben.scg.com/graphql",
  },
  uat: {
    HOST: "https://reimbursement-app-flexben-uat.scg.com",
    API_ENDPOINT: "https://reimbursement-app-flexben-uat.scg.com/graphql",
  },
  staging: {
    HOST: "https://dev.app.reimbursement.ldrive.io",
    API_ENDPOINT: "https://dev.app.reimbursement.ldrive.io/graphql",
  },
  local: {
    HOST: "http://localhost:21603",
    API_ENDPOINT: "http://localhost:21601/graphql",
  },
}

const getEnvByHost = () => (isClient ? findKey(urlConfig, { HOST: window.location.origin }) : null)

export const nodeEnv = getEnvByHost() || "local"

const MOCK_MSAL_CONFIG = {
  production: {
    REACT_APP_MSAL_CLIENT_ID: "f415bf9f-ebca-4b00-b2ff-af62bbc71cf4",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/7674d426-2045-4473-9a6c-0450e83913c3",
    REACT_APP_MSAL_REDIRECT_URI: "https://reimbursement-app-flexben.scg.com",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "https://reimbursement-app-flexben.scg.com",
  },
  uat: {
    REACT_APP_MSAL_CLIENT_ID: "3f40ba3b-03b7-4f87-a056-3b56c363273e",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/6eaf065a-e93f-4f82-9f31-8976ef8d41ae",
    REACT_APP_MSAL_REDIRECT_URI: "https://reimbursement-app-flexben-uat.scg.com",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "https://reimbursement-app-flexben-uat.scg.com",
  },
  staging: {
    REACT_APP_MSAL_CLIENT_ID: "3f40ba3b-03b7-4f87-a056-3b56c363273e",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/6eaf065a-e93f-4f82-9f31-8976ef8d41ae",
    REACT_APP_MSAL_REDIRECT_URI: "https://dev.app.reimbursement.ldrive.io",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "https://dev.app.reimbursement.ldrive.io",
  },
  local: {
    REACT_APP_MSAL_CLIENT_ID: "b672190d-551a-4eb1-af18-9b4acf6c6eea",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI: "http://localhost:21603",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "http://localhost:21603",
  },
}

const MOCK_CONFIG = MOCK_MSAL_CONFIG[nodeEnv as keyof typeof MOCK_MSAL_CONFIG]

const envalid = cleanEnv(process.env, {
  REACT_APP_ENV: str({ choices: ["production", "uat", "staging", "e2eStaging", "local"], default: nodeEnv }),
  REACT_APP_MSAL_CLIENT_ID: str({ default: MOCK_CONFIG.REACT_APP_MSAL_CLIENT_ID }),
  REACT_APP_MSAL_AUTHORITY: url({ default: MOCK_CONFIG.REACT_APP_MSAL_AUTHORITY }),
  REACT_APP_MSAL_REDIRECT_URI: url({ default: MOCK_CONFIG.REACT_APP_MSAL_REDIRECT_URI }),
  REACT_APP_MSAL_LOGOUT_REDIRECT_URI: url({ default: MOCK_CONFIG.REACT_APP_MSAL_LOGOUT_REDIRECT_URI }),
})

const env = {
  // ...envalid,
  REACT_APP_MSAL_CLIENT_ID: MOCK_CONFIG.REACT_APP_MSAL_CLIENT_ID,
  REACT_APP_MSAL_AUTHORITY: MOCK_CONFIG.REACT_APP_MSAL_AUTHORITY,
  REACT_APP_MSAL_REDIRECT_URI: MOCK_CONFIG.REACT_APP_MSAL_REDIRECT_URI,
  REACT_APP_MSAL_LOGOUT_REDIRECT_URI: MOCK_CONFIG.REACT_APP_MSAL_LOGOUT_REDIRECT_URI,
  REACT_APP_LOGIN_SECRET: "zaisae4ael1Ahwutheiy5iequah7zu1s",
  HOST: urlConfig[nodeEnv as keyof typeof urlConfig].HOST,
  API_ENDPOINT: urlConfig[nodeEnv as keyof typeof urlConfig].API_ENDPOINT,
}

console.log("window.location.origin", window.location.origin)
console.log("host", urlConfig[nodeEnv as keyof typeof urlConfig])

export default env
