import { pick, mapValues } from "lodash"
import {
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from "@material-ui/system"
import styled from "styled-components/macro"
import * as MuiCore from "@material-ui/core"
import * as MuiLab from "@material-ui/lab"
import * as FormikMuiCore from "formik-material-ui"
import * as FormikMuiPicker from "formik-material-ui-pickers"

import CustomButton from "./Button"
import CustomCheckbox from "./Checkbox"
import CustomErrorMessage from "./ErrorMessage"
import CustomSelect from "./Select"
import CustomTypography from "./Typography"
import CustomRadioGroup from "./RadioGroup"
import CustomTextField from "./TextField"
import CustomSwitchInput from "./SwitchInput"
import CustomDatePicker from "./DatePicker"
import CustomLink from "./Link"
import { MuiIcon as CustomMuiIcon } from "./MuiIcon"
import CustomIcon from "./Icon"

const makeBoxProps = (Component: any) =>
  styled(Component)(borders, display, flexbox, palette, positions, shadows, sizing, spacing, typography)
// @ts-ignore
CustomTextField.Email = makeBoxProps(CustomTextField.Email)
// @ts-ignore
CustomTextField.PhoneNumber = makeBoxProps(CustomTextField.PhoneNumber)
// @ts-ignore
CustomTextField.TaxId = makeBoxProps(CustomTextField.TaxId)

const CustomMuiTextField = makeBoxProps(MuiCore.TextField)
// @ts-ignore
export const Email = CustomTextField.Email
// @ts-ignore
export const PhoneNumber = CustomTextField.PhoneNumber
// @ts-ignore
export const TaxId = CustomTextField.TaxId

const MuiComponents = mapValues(
  {
    ...pick(MuiCore, [
      "Avatar",
      "Paper",
      "Menu",
      "Breadcrumbs",
      "Divider",
      "IconButton",
      "Radio",
      "FormControlLabel",
      "Grid",
      "Chip",
      "Container",
    ]),
    ...pick(MuiLab, ["Alert"]),
    ...pick(FormikMuiCore, []),
    Button: CustomButton,
    Link: CustomLink,
    Checkbox: CustomCheckbox,
    ErrorMessage: CustomErrorMessage,
    Select: CustomSelect,
    RadioGroup: CustomRadioGroup,
    TextField: CustomTextField,
    SwitchInput: CustomSwitchInput,
    MuiTextField: CustomMuiTextField,
    DatePicker: CustomDatePicker,
  },
  makeBoxProps,
)

export const {
  Avatar,
  Paper,
  TextField,
  Alert,
  Button,
  Link,
  Checkbox,
  Menu,
  Breadcrumbs,
  Divider,
  ErrorMessage,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  Grid,
  Chip,
  SwitchInput,
  MuiTextField,
  Container,
} = MuiComponents

export const KeyboardDatePicker = FormikMuiPicker.KeyboardDatePicker

export const Typography = CustomTypography
export const MuiIcon = CustomMuiIcon
export const Icon = CustomIcon

export const Box = MuiCore.Box
export const Tabs = MuiCore.Tabs // TODO: remove unused Tabs
export const Tab = MuiCore.Tab // TODO: remove unused Tab
export const TabPanel = MuiLab.TabPanel // TODO: remove unused TabPanel
export const Accordion = MuiCore.Accordion // TODO: remove unused Accordion
export const AccordionSummary = MuiCore.AccordionSummary // TODO: remove unused AccordionSummary
export const AccordionDetails = MuiCore.AccordionDetails // TODO: remove unused AccordionDetails

export const ClickAwayListener = MuiCore.ClickAwayListener

export { DataGrid, GridToolbar } from "@material-ui/data-grid"
export { default as DeleteIcon } from "@material-ui/icons/Delete"

export { Helmet } from "react-helmet-async"

export { default as Hidden } from "./Hidden"
export { default as BrowseButton } from "./BrowseButton"

export { default as Form } from "./Form"
export { default as Field } from "./Field"
export { default as FieldArray } from "./FieldArray"

export { default as BrowserRouter } from "./BrowserRouter"
export { default as Redirect } from "./Redirect"
export { default as Route } from "./Route"
export { default as Switch } from "./Switch"

export { default as Modal } from "./Modal"
export { default as Notification } from "./Notification"
export { default as ExcelGenerator } from "./ExcelGenerator"
export { default as ImageLightbox } from "./ImageLightbox"

// export { default as Icon } from "./Icon"
export { default as PopMenu } from "./PopMenu"
export { default as UploadAvatar } from "./UploadAvatar"

export { default as Table } from "./Table"
export { default as Editor } from "./Editor"

export const DatePicker = CustomDatePicker
export { default as ProgressBar } from "./ProgressBar"
