import { compose, withHooks } from "enhancers"
import { TextField } from "formik-material-ui"

const Textarea = (props: any) => <TextField {...props} />

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    return {
      ...props,
      multiline: true,
      minRows: 4,
      maxRows: 4,
      style: {
        borderRadius: 4,
      },
    }
  }),
)

export default enhancer(Textarea)
