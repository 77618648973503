import { linearGradient } from "polished"

const colorConfigs = {
  "Gray/Flat Button Bg": "#CBD0D1",
  "Text/Dark": "#B39E9A",
  "Text/Blue": "#313283",
  "Text/Primary Pink": "#E31C79",
  "Text/Gray": "#CBD0D1",
  "Text/Black2": "#2B2B2B",
  "Text/Gray Preview": "#606365",
  "Text/Gray Success": "#A2AAAD",
  "Gray/Card Border, Line": "#A2AAAD",
  "Text/Dark Grey": "#767676",
  "Other/Danger": "#FF0000",
  "Other/Warning": "#FAA61B",
  "Other Light/Danger": "#FCECE9",
  "Primary/Brown": "#805D57",
  "Dark Blue/Primary Text": "#313283",
  //
  "Gray/Primary Text": "#2B2B2B",
  "Light Blue/Flat Button Background": "#E3F1F9",
  "Error/Error Text": "#FF0000",
  Success: "#009989",
  Warning: "#FAA61B",
  "Error/Line": "#FF0000",
  "Primary/Line": "#313283",
  "Primary/Hover": "#313283",
  "Primary/Primary Text": "#313283",
  "Gray/Bg": "#F5F6F6",
  "White / White": "#FFFFFF",
  "Primary/Background": "#ECEDF8",
  "Text/Primary Text": "#000000",
  "Text/Secondary Text": "#333333",
  "Text/Line": "#7C7C7C",
  "Text/Placeholder": "#585858",
  "Text/Background": "#EAEAEA",
}

const backGroundColorGradient = linearGradient({
  colorStops: [`${colorConfigs["Primary/Primary Text"]} 81.24%`, `${colorConfigs["Success"]} 96.64%`],
  toDirection: "213deg",
  fallback: colorConfigs["Primary/Primary Text"],
})

const colors = { colors: colorConfigs, gradient: backGroundColorGradient }

export default colors
