export enum INPUT_TYPE {
  DATE = "DATE",
  TIME_PICKER = "TIME_PICKER",
  TEXT = "TEXT",
  TEXTAREA = "TEXTAREA",
  SELECT = "SELECT",
  NUMBER = "NUMBER",
  CURRENCY = "CURRENCY",
  RADIO = "RADIO",
}
