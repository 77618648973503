import { SvgIconProps } from "@material-ui/core"
import * as MaterialIcon from "@material-ui/icons"
import React from "react"
import { ReactComponent as NotFoundIcon } from "assets/icon/error_outline.svg"

export type MuiIconCollections = keyof typeof MaterialIcon

export interface CustomMuiIconProps extends Omit<SvgIconProps, "htmlColor" | "color"> {
  name?: MuiIconCollections
  color?: string
}

export const MuiIcon = (props: CustomMuiIconProps) => {
  const { name, color, ...rest } = props

  if (MaterialIcon[name as MuiIconCollections])
    return React.createElement(MaterialIcon[name as MuiIconCollections], {
      htmlColor: color,
      ...rest,
    })
  else return <NotFoundIcon />
}
