import { ApolloError, gql, useLazyQuery, useQuery } from "@apollo/client"
import { compose, withHooks } from "enhancers"
import { useEffect, useMemo } from "react"
import paths from "routes/paths"

import { useMsal } from "@azure/msal-react"
import { getToken, removeToken, setToken } from "api"
import { Redirect, Route, Switch } from "components"
import { AES, enc, mode } from "crypto-js"
import { msalInstance } from "index"
import { isEmpty } from "lodash"
import Page404 from "pages/auth/Page404"
import Page498 from "pages/auth/Page498"
import Page500 from "pages/auth/Page500"
import SignIn from "pages/auth/SignIn"
import ClaimDetailPage from "pages/main/claim-detail/index"
import ClaimRequestPage from "pages/main/claim-requests"
import ApprovalPage from "pages/main/claim-requests/detail"
import DashboardComponent from "pages/main/dashboard"
import dashboardDetail from "pages/main/dashboard/dashboardDetail"
import HistoryComponent from "pages/main/e-claim-history"
import HistoryDetailComponent from "pages/main/e-claim-history/detail"
import eClaimLanding from "pages/main/e-claim-landing"
import ListPageComponent from "pages/main/e-claim-list/index"
import userStore from "stores/userStore"
import env from "env"
import appStore from "stores/appStore"
import Loader from "components/Loader"

const MainPages = () => (
  <Switch>
    <Route path={paths.landingPath()} exact component={eClaimLanding} />
    <Route path={paths.claimDetailPath()} exact component={ClaimDetailPage} />
    <Route path={paths.listPath()} exact component={ListPageComponent} />
    <Route path={paths.dashboardPath()} exact component={DashboardComponent} />
    <Route path={paths.dashboardDetailPath(":id")} exact component={dashboardDetail} />
    <Route path={paths.historyPath()} exact component={HistoryComponent} />
    <Route path={paths.historyDetailPath(":id")} exact component={HistoryDetailComponent} />
    <Route path={paths.claimRequestsPath()} exact component={ClaimRequestPage} />
    <Route path={paths.approvalRequestPath(":id")} exact component={ApprovalPage} />

    <Redirect to={paths.landingPath()} />
  </Switch>
)

const GuestPages = () => (
  <Switch>
    <Route path={paths.page404Path()} exact component={Page404} />

    <Route path={paths.page500Path()} exact component={Page500} />
    <Route path={paths.page498Path()} exact component={Page498} />

    <Route path={paths.signInPath()} exact component={SignIn} />

    <Redirect to={paths.signInPath()} />
  </Switch>
)

interface RoutesProps {
  initialized: boolean
  isAuthorized: boolean
  loading: boolean
}

const Routes = (props: RoutesProps) => {
  const { initialized, isAuthorized, loading } = props
  if (loading) return <Loader />
  else if (!initialized || !isAuthorized) return <GuestPages />
  else return <MainPages />
}

export const API = {
  SIGN_IN_VIA_AZURE_AD: gql`
    mutation SIGN_IN_VIA_AZURE_AD($email: String!, $authToken: String!, $preferredUserName: String!) {
      signInViaAzureAd(input: { email: $email, authToken: $authToken, preferredUserName: $preferredUserName }) {
        id
        officialMail
        user {
          userToken
          userType
          id
        }
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
          department
          title
        }
      }
    }
  `,
  DEBUG_WITH_MS_TEAM: gql`
    mutation DEBUG_WITH_MS_TEAM($tag: String!, $messages: JSON!) {
      debugWithMsTeam(input: { tag: $tag, messages: $messages })
    }
  `,
}

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useState, useUrlParam, useMutation } = hooks
    const { token: encryptedToken, withPortalToken } = useUrlParam()

    const [isReady, setIsReady] = useState(false)
    const [isMsalLoading, setIsMsalLoading] = useState(true)

    const { accounts } = useMsal()

    const [signInWithAzureAd] = useMutation(API.SIGN_IN_VIA_AZURE_AD, {
      onCompleted: (data: any) => {
        const { signInViaAzureAd } = data
        console.log("LOGIN WITH AZURE AD: ", signInViaAzureAd)

        if (signInViaAzureAd.user.userToken) {
          setToken(signInViaAzureAd.user.userToken)
          // window.location.reload()
          fetchCurrentUser()
        }
        setIsMsalLoading(false)
      },
      onError: (data: any) => {
        console.log("LOGIN WITH AZURE AD ERROR : ", data)
        msalInstance.setActiveAccount(null)
        removeToken()
        setIsMsalLoading(false)
      },
    })

    const [fetchCurrentUser, { loading, error: currentUserError, data: currentUser }] = useLazyQuery(
      API.GET_CURRENT_USER,
      {
        fetchPolicy: "network-only",
        onCompleted: (data: any) => {
          userStore.setCurrentUser(data.getCurrentUser)
          let title = JSON.parse(localStorage.getItem("themeName") as string)

          // if (department !== data.getCurrentUser?.employee?.department) {
          if (title !== data.getCurrentUser?.employee?.title) {
            const { employee } = data.getCurrentUser || {}
            const { title } = employee || {}

            localStorage.setItem("themeName", JSON.stringify(title))
            window.location.href = paths.homePath()
          }

          setIsReady(true)
        },
        onError: (error: ApolloError) => {
          console.log("GET CURRENT USER ERROR : ", error)
          userStore.setCurrentUser(null)
          setIsReady(false)
          removeToken()
        },
      },
    )

    const [debugWithMsTeam] = useMutation(API.DEBUG_WITH_MS_TEAM)

    const pageLoading = useMemo(() => loading || isMsalLoading, [isMsalLoading, loading])

    const initialized = !pageLoading && isReady
    const isAuthorized = useMemo(() => {
      return !!(!currentUserError && currentUser)
    }, [currentUserError, currentUser])

    useEffect(() => {
      if (withPortalToken) {
        setIsMsalLoading(false)
        removeToken()
        setToken(withPortalToken)
      } else if (encryptedToken) {
        setIsMsalLoading(false)
        localStorage.setItem("Encrypted_token", encryptedToken)
        removeToken()
        const secret = env.REACT_APP_LOGIN_SECRET

        let secSpec = enc.Utf8.parse(secret)
        let ivSpec = enc.Utf8.parse("")

        const decrypted = AES.decrypt(encryptedToken, secSpec, {
          iv: ivSpec,
          mode: mode.CBC,
        })
        const email = decrypted.toString(enc.Utf8)
        if (!isEmpty(email)) {
          signInWithAzureAd({
            variables: {
              email: email,
              authToken: "",
              preferredUserName: email,
            },
          })
        }
      } else if (accounts) {
        msalInstance
          .handleRedirectPromise()
          .then(async () => {
            const account = accounts[0]
            const currentToken = getToken()
            if (account && !currentToken) {
              if (account) debugWithMsTeam({ variables: { tag: "MSAL", messages: account } })
              await signInWithAzureAd({
                variables: {
                  email: account?.idTokenClaims?.email,
                  authToken: "",
                  preferredUserName: account.idTokenClaims?.preferred_username,
                },
              })
            } else {
              setIsMsalLoading(false)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const currentToken = getToken()

      if (currentToken && !currentUser) {
        fetchCurrentUser()
      }

      // // Sign in with portal token
      // if (withPortalToken) {
      //   removeToken()
      //   setToken(withPortalToken)
      //   return
      // }

      // // Sign in with encrypted token
      // if (encryptedToken) {
      //   localStorage.setItem("Encrypted_token", encryptedToken)
      //   removeToken()
      //   const secret = env.REACT_APP_LOGIN_SECRET

      //   let secSpec = enc.Utf8.parse(secret)
      //   let ivSpec = enc.Utf8.parse("")

      //   const decrypted = AES.decrypt(encryptedToken, secSpec, {
      //     iv: ivSpec,
      //     mode: mode.CBC,
      //   })
      //   const email = decrypted.toString(enc.Utf8)
      //   if (!isEmpty(email)) {
      //     signInWithAzureAd({
      //       variables: {
      //         email: email,
      //         authToken: "",
      //         preferredUserName: email,
      //       },
      //     })
      //   }
      //   return
      // }

      // // Sign in with Azure AD
      // if (accounts) {
      //   msalInstance
      //     .handleRedirectPromise()
      //     .then(async () => {
      //       const account = accounts[0]
      //       const currentToken = getToken()
      //       if (account && !currentToken) {
      //         if (account) debugWithMsTeam({ variables: { tag: "MSAL", messages: account } })
      //         await signInWithAzureAd({
      //           variables: {
      //             email: account?.idTokenClaims?.email,
      //             authToken: "",
      //             preferredUserName: account.idTokenClaims?.preferred_username,
      //           },
      //         })
      //       } else if (currentToken && !currentUser) {
      //         fetchCurrentUser()
      //       } else {
      //         setIsMsalLoading(false)
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }
    }, [withPortalToken, encryptedToken, accounts, debugWithMsTeam, signInWithAzureAd, currentUser, fetchCurrentUser])

    return {
      initialized,
      isAuthorized,
      loading: pageLoading,
    }
  }),
)

export default enhancer(Routes)
