import { Step, StepIconProps, StepLabel, Stepper } from "@material-ui/core"
import { Box, Typography } from "components"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import styled from "styled-components"
import Theme from "theme/custom"

const CustomStepper = styled(Stepper)<{ claimstatus: EnumClaimRequestStatus }>`
  .MuiStepConnector-root.MuiStepConnector-vertical {
    margin-left: 6px;
    padding: 0px;
  }
  .MuiStepConnector-lineVertical {
    min-height: 26px;
    margin-top: -9px;
    margin-bottom: -9px;
    width: 1px;
  }
`

const CustomStepLabel = styled(StepLabel)`
  .MuiStepIcon-root {
    font-size: 14px;
  }
`

const MappingStatusLabel = (status: EnumClaimRequestStatus) => {
  switch (status) {
    case EnumClaimRequestStatus.APPROVED:
      return "อนุมัติแล้ว"
    case EnumClaimRequestStatus.WAITING:
      return "รออนุมัติ"
    case EnumClaimRequestStatus.REJECTED:
      return "ไม่อนุมัติ"
    case EnumClaimRequestStatus.CANCELED:
      return "ยกเลิก"
    default:
      break
  }
}

const StatusStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean; error?: boolean }
}>(({ ownerState }) => ({
  backgroundColor: Theme.colors["Warning"],
  zIndex: 1,
  color: Theme.colors["White / White"],
  width: 14,
  height: 14,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  border: `1px solid ${Theme.colors["White / White"]}`,
  alignItems: "center",
  ...(ownerState.active && {
    background: Theme.colors["Warning"],
  }),
  ...(ownerState.completed && {
    background: Theme.colors["Success"],
  }),
  ...(ownerState.error && {
    background: Theme.colors["Error/Error Text"],
  }),
}))

function StatusStepIcon(props: StepIconProps) {
  const { active, completed, error, className } = props

  return <StatusStepIconRoot ownerState={{ completed, active, error }} className={className}></StatusStepIconRoot>
}

interface StepStatusProps {
  claimStatus: EnumClaimRequestStatus
  workflows: []
  workflowStep: number
  canceled?: string
}

export const StepStatus = (props: StepStatusProps) => {
  const { workflowStep, claimStatus, workflows, canceled } = props
  return (
    <>
      {claimStatus === EnumClaimRequestStatus.CANCELED ? (
        <CustomStepper
          activeStep={workflowStep}
          orientation="vertical"
          style={{ padding: 0, background: "none" }}
          claimstatus={claimStatus}
        >
          <Step>
            <CustomStepLabel error StepIconComponent={StatusStepIcon}>
              <Box display="flex" alignItems="center">
                <Box minWidth="80px" mr="8px">
                  <Typography color="White / White" variant="body1">
                    ยกเลิก
                  </Typography>
                </Box>
                <Typography color="White / White" variant="subtitle1">
                  ({canceled})
                </Typography>
              </Box>
            </CustomStepLabel>
          </Step>
        </CustomStepper>
      ) : (
        <CustomStepper
          activeStep={workflowStep}
          orientation="vertical"
          style={{ padding: 0, background: "none" }}
          claimstatus={claimStatus}
        >
          {workflows.map((flow: any, index: number) => {
            const labelProps: {
              optional?: React.ReactNode
              active?: boolean
              error?: boolean
              completed?: boolean
            } = {}
            if (flow.status === EnumClaimRequestStatus.REJECTED || flow.status === EnumClaimRequestStatus.CANCELED)
              labelProps.error = true
            return (
              <Step key={flow.seq}>
                <CustomStepLabel {...labelProps} StepIconComponent={StatusStepIcon}>
                  <Box display="flex" alignItems="center">
                    <Box minWidth="80px" mr="8px">
                      <Typography color="White / White" variant="body1">
                        {claimStatus === EnumClaimRequestStatus.REJECTED &&
                        flow.status === EnumClaimRequestStatus.WAITING
                          ? "-"
                          : MappingStatusLabel(flow.status)}
                      </Typography>
                    </Box>
                    <Typography color="White / White" variant="subtitle1">
                      ({flow.approverTxt})
                    </Typography>
                  </Box>
                </CustomStepLabel>
              </Step>
            )
          })}
        </CustomStepper>
      )}
    </>
  )
}
