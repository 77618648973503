import styled from "styled-components"
import { Box, Typography, MuiIcon } from "components"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { useCallback } from "react"
import Theme from "theme/custom"

const StatusItem = styled("div")<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  padding: 12px 0px;
  border: ${(props) => (props.selected ? "1px solid dark" : "0px")};
  color: ${(props) => (props.selected ? Theme.colors["Primary/Primary Text"] : "#CBD0D1")};
  border-bottom: 1px solid ${(props) => (props.selected ? Theme.colors["Primary/Primary Text"] : "#A2AAAD")};
  box-sizing: border-box;
`

const StatusBox = styled(Box)`
  display: flex;
`

const CustomIconList = styled(MuiIcon)`
  font-size: 16px;
  margin-right: 2px;
`

interface StatusTabProps {
  onClick?: (status: EnumClaimRequestStatus) => void
  selectedStatus?: EnumClaimRequestStatus
  hasCancel?: boolean
}

export const StatusTab = (props: StatusTabProps) => {
  const { selectedStatus, onClick, hasCancel = true } = props
  const handleClick = useCallback(
    (status) => {
      if (onClick) onClick(status)
    },
    [onClick],
  )

  return (
    <StatusBox>
      <StatusItem
        onClick={() => handleClick(EnumClaimRequestStatus.WAITING)}
        border-right
        selected={selectedStatus === EnumClaimRequestStatus.WAITING}
      >
        <CustomIconList name="WatchLater" />
        <Typography variant="subtitle1">รออนุมัติ</Typography>
      </StatusItem>
      <StatusItem
        onClick={() => handleClick(EnumClaimRequestStatus.APPROVED)}
        border-right
        selected={selectedStatus === EnumClaimRequestStatus.APPROVED}
      >
        <CustomIconList name="CheckCircle" />
        <Typography variant="subtitle1">อนุมัติ</Typography>
      </StatusItem>
      <StatusItem
        onClick={() => handleClick(EnumClaimRequestStatus.REJECTED)}
        border-right
        selected={selectedStatus === EnumClaimRequestStatus.REJECTED}
      >
        <CustomIconList name="Cancel" />
        <Typography variant="subtitle1">ไม่อนุมัติ</Typography>
      </StatusItem>
      {hasCancel && (
        <StatusItem
          onClick={() => handleClick(EnumClaimRequestStatus.CANCELED)}
          border-right={false}
          selected={selectedStatus === EnumClaimRequestStatus.CANCELED}
        >
          <CustomIconList name="RemoveCircle" />
          <Typography variant="subtitle1">ยกเลิก</Typography>
        </StatusItem>
      )}
    </StatusBox>
  )
}
